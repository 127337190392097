// ----------------------------------------------------------------------------
// BASE FLEX SETUP
// ----------------------------------------------------------------------------


.o-flex {
    display: flex;
}

// Use for tablet or above
.md-flex {

    @include medium {
        display: flex;
    }

}

.xmd-flex {

    @include xmedium {
        display: flex;
    }

}

// Use for desktop or above
.lg-flex {

    @include large {
        display: flex;
    }

}


// Break a flexbox layout and stack for mobile
.narrow-stack {

    @include narrow {
        display: block;
        background: none !important;
    }

}


// ----------------------------------------------------------------------------
// ALIGN CHILDREN FROM PARENT
// ----------------------------------------------------------------------------

.flex--align-top {
    align-items: flex-start;
}

.flex--center {
    align-items: center;
}

.lg-flex--center {
    @include large {
        align-items: center;
    }
}

.flex--bottom {
    align-items: flex-end;
}


// ----------------------------------------------------------------------------
// ALIGN CHILDREN
// ----------------------------------------------------------------------------

.layout-flex-item--top {
    align-self: flex-start;
}

.flex-item--center {
    align-self: center;
}

.flex-item--bottom {
    align-self: flex-end;
}

.flex-item--right {
    margin-left: auto;
}


// ----------------------------------------------------------------------------
// LAYOUT SPACING
// ----------------------------------------------------------------------------

.flex--justify-center {
    justify-content: center;
}

.flex--justify-end {
    justify-content: flex-end;
}

.flex--space-between {
    justify-content: space-between;
}

.flex--space-evenly {
    justify-content: space-evenly;
}

.flex--padding > div {
    padding-left: 1em;
    padding-right: 1em;

    @include narrow {
        padding-top: 1em;
    }

}


// ----------------------------------------------------------------------------
// HELPER CLASSES
// ----------------------------------------------------------------------------

.flex--row-reverse {
    flex-direction: row-reverse;
}

.flex--row {
    flex-direction: row;
}

.md-flex--row-reverse {
    @include medium {
        flex-direction: row-reverse;
    }
}

.flex--column {
    flex-direction: column;
}

.layout-flex img {
    object-fit: scale-down;
}


.flex--column-last-bottom {
    height: 100%;

    .align-bottom {
        margin-top: auto;
    }

}

.flex--wrap {
    flex-wrap: wrap;
}

.flex--grow-1 {
    flex-grow: 1;
}


// ----------------------------------------------------------------------------
// Preset layouts
// ----------------------------------------------------------------------------


.flex-layout {
    flex-wrap: wrap;
    align-items: stretch;
    justify-content: space-between;
}

.flex-layout--halfs {

    > * {
        margin-bottom: 50px;

        @include medium {
            flex: 1;
            max-width: 48%;
            flex: 0 0 48%;
        }

    }

}

.lg-flex-layout--halfs {
    @include large {
        > * {
            flex: 1;
            max-width: 48%;
            flex: 0 0 48%;
        }
    }
}

.register-flex-layout--halfs {

    > * {
        margin-bottom: 50px;

        @include xmedium {
            flex: 1;
            max-width: 48%;
            flex: 0 0 48%;
        }

    }

}


.flex-layout--thirds {

    @include medium {
        justify-content: space-between;
    }

    @include large {
        justify-content: flex-start;
    }

    > * {

        @include narrow {
            margin-bottom: 50px;
        }

        @include medium {
            max-width: 48%;
            flex: 0 0 48%;
            margin-bottom: 50px;
        }

        @include large {
            flex: 1;
            max-width: 31%;
            flex: 0 0 31%;
            margin-right: 3.5%;

            &:nth-child(3n) {
                margin-right: 0;
            }

        }

    }

}

.lg-flex-layout--thirds {
    @include large {
        > * {
            flex: 1;
            max-width: 31%;
            flex: 0 0 31%;
            margin-right: 3.5%;

            &:nth-child(3n) {
                margin-right: 0;
            }
        }
    }
}



.flex-layout--quarters {

    > * {
        margin-bottom: 50px;

        @include medium {
            max-width: 48%;
            flex: 0 0 48%;
        }

        @include large {
            max-width: 22%;
            flex: 0 0 22%;
        }

    }

}

.flex-layout--quarters--spacer {
    &:after {
        content: "";
        flex: 1 0 22%;
    }
}

.lg-flex-layout--quarters {
    @include large {
        > * {
            margin-bottom: 50px;
            max-width: 22%;
            flex: 0 0 22%;
        }
    }
}


.flex-layout--border-bottom {
    border-bottom: 1px solid #EBEDF0;
    // padding-bottom: 70px;
}


.flex-layout--summary {
    justify-content: space-between;

    > * {
        &:first-child {
            flex: 0 0 25%
        }
    }
}

.lg-flex-first {
    @include large {
        order: 1;
    }
}

.lg-flex-last {
    @include large {
        order: 2;

        img {
            text-align: right;
        }
    }
}


.flex--center-screen {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}
