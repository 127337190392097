

.border-top-bottom {
    border-top: 1px solid #E5EEF3;
    border-bottom: 1px solid #E5EEF3;
}

.border-bottom {
    border-bottom: 1px solid #E5EEF3;
}


.tab-nav {
    text-transform: uppercase;
    margin: 0;
    padding: 0;

}

.tab-no-wrap {
    @include narrow {
        width: auto !important;
        display: inline-block !important;
    }
}

.tab-nav__item {
    margin: 0;

    &.active {

        .tab-nav__link {
            color: #333333;
            transition: $base-transition;
        }

        .tab-nav__link:after {
            // border-bottom: 1px solid #333333;
            // color: #333333;
            content: " ";
            width: 100%;
            height: 1px;
            background-color: #333;
            position: absolute;
            bottom: -1px;
            left: 0;

        }
    }

    &.inactive {
        opacity: 0.5;
    }

}

.tab-nav__link {
    display: inline-block;
    padding: 15px 0;
    position: relative;
    cursor: pointer;
}

.tabs-wrapper {
    xpadding-top: 50px;
}

// .tab {
//     opacity: 0;
//     transition: $base-transition;
//     display: none;
//     // position: absolute;
//     // top: 0;
// }

// .tab--visible {
//     opacity: 1;
//     transition: $base-transition;
//     display: block;
// }