.u-br-none {
  border-right: none !important;
}

.u-btb-white-frame {
  border-top: 1px solid $color-white;
  border-bottom: 1px solid $color-white;
  padding: 10px 0;
  @include narrow {
    padding: 6px 0;
  }
}

.u-no-border {
	border: none;
}

.u-bl-thin {
  @include xmedium {
    border-left: solid thin #E7E7E7;
 }
}

.u-b--round {
  border-radius: 7px;
}

.u-b--circular {
  border-radius: 50%;
}

.u-bb-thin {
  border-bottom: 1px solid #edeeee;
}

.u-bt-thin {
  border-top: 1px solid #edeeee;
}
