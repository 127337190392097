

// h1, h2, h3, h4 {
//     margin: 1.414em 0 0.5em;
//     font-weight: inherit;
//     line-height: 1.3;
// }

// p, ol, ul {
//     margin-top: 0;
//     margin-bottom: 1.3em;

//     &:last-child {
//         margin-bottom: 0;
//     }

// }




// sass-lint:disable single-line-per-selector
h1, h2, h3, h4, h5, h6,
ul, ol, dl,
blockquote, p, address,
.block-item,
hr,
table,
fieldset, figure,
pre {
    margin-bottom: 0;
    // margin-bottom: $base-spacing-unit;

    // &:last-child {
    //     margin-bottom: 0;
    // }
}

ul, ol,
dd {
    margin-left: $base-spacing-unit;
}




// .wrapper {

//     > * + * {
//         margin-top: 25px;
//     }

// }