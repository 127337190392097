

// .u-color-penny-blue {
//     color: $color-penny-blue;
// }


// .u-color-penny-red {
//     color: $color-penny-red;
// }


// .u-color-penny-black {
//     color: $color-penny-black;
// }

.u-align-left {
  margin-left: 0;
  padding-left: 0;
}

.u-color-white {
  color: $color-white;
}

.u-color-blue {
  color: #097DD0 !important;
}

.u-color-orange {
  color: $color-payments-orange !important;
}

.u-color-dark-gray {
  color: #6E6E6E !important;
}

.u-bg-color-blue {
  background-color: $color-payments-blue;
}

.u-bg-color-red {
  background-color: $color-payments-red;
}

.u-bg-color-green {
  background-color: $color-payments-teal;
}

.u-bg-color-teal {
  background-color: $color-payments-teal;
}

.u-bg-color-orange {
  background-color: $color-payments-orange;
}

.u-bg-color-purple {
  background-color: $color-payments-purple;
}

.u-bg-color-light-grey {
  background-color: #F9FBFC;
}

.u-bg-color-grey {
  background-color: #E8EDEF;
}

.u-bg-color-off-white {
  background-color: #FAFCFD;
}

.u-bg-color-dark-grey {
  background-color: #F0F0F0;
}

.u-bg-color-light-green {
  background-color: #D6F6EF !important;
}

.u-bg-color-black-80 {
  background-color: $color-black-80;
}

.u-bg-color-black-70 {
  background-color: $color-black-70;
}

.u-bg-none {
  background: none;
}
