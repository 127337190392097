body {
    // padding to account for fixed header
    @include narrow {
        padding-top: 60px;
    }

    padding-top: 102px;
}

.page__header {
    @include fixed($t:0, $z:900);
    width: 100%;
    background-color: $color-black;

    transition: all 0.5s ease;
    animation-duration: .5s;
    animation-fill-mode: both;
    will-change: transform,opacity;

    // padding: 25px 0;

    &.slideDown {
        animation-name: slideDown;
    }

    &.slideUp {
        animation-name: slideUp;
    }

    a {
        color: white;
    }

    .wrapper {
        @include medium {
            padding-left: 0;
            padding-right: 0;
        }
        @include large {
            padding-left: 1.5em;
            padding-right: 1.5em;
        }
    }

}

.page__header-nav__item {
    padding: $spacing;
    display: inline-block;
}

.page__header-nav--sm {
    display: block;
    @include large {
        display: none;
    }
}

.page__header-nav--lg {
    display: none;
    @include large {
        display: block;
    }
}

.header {
    width: 100%;
    max-width: 1360px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @include narrow {
        height: 60px;
    }

    @include medium {
        height: 102px;
        justify-content: start;
        padding: 0 1.5rem;
    }

    @include large {
        padding: 0;
    }

    .logo--wrapper {
        margin-right: 1rem;

        @include medium {
            margin-left: 2rem;
        }

        @include large {
            margin-left: 0;
        }

        @include xxlarge {
            margin-right: 3rem;
        }

    }

    .logo-api {
        height: 34px;
        width: 125px;
        margin-top: 0.5rem;

        @include bp768 {
            top: 28px;
            width: 170px;
            height: 62px;
        }
    }

    .logo-payments {
        width: 176px;
        height: 30px;
        margin-top: 0.325rem;

        @include narrow {
            width: 132px;
            height: 21px;
            margin-top: 10px;
        }

    }
}

.nav__links {
    display: none;

    @include large {
        display: block;
    }
}

.nav__link {
    display: inline-block;
    height: 100%;
    padding: 40px 0;
    margin-left: 20px;
    margin-right: 20px;
    font-size: 14px;
    font-weight: 700;
    text-transform: uppercase;
    position: relative;

    @include large {
        margin-left: 11px;
        margin-right: 12px;
    }

    @include xxlarge {
        margin-left: 20px;
        margin-right: 20px;
    }

    &:before {
        content: " ";
        bottom: 0;
        width: 100%;
        height: 0;
        background-color: white;
        position: absolute;
        opacity: 0;
        transition: $base-transition;
    }

    &.active:before,
    &:hover:before {
        opacity: 1;
        height: 4px;
    }

}

.nav__link--lowercase {
    font-weight: 400;
    text-transform: none;
}

.nav__login-logout-search {
    margin-left: auto
}

.global-search {
    display: inline-block;
    position: relative;
    margin-right: 20px;

    &:after {
        content: " ";
        position: absolute;
        top: -22px;
        right: -15px;
        height: 35px;
        width: 1px;
        background: rgba(255, 255, 255, 0.20);
        margin-right: 0px;
    }

}

.iblefkh {
    display: inline-block;
    .api-center-login {
        display: none;
        visibility: hidden;
        @include xmedium() {
            display: inline-block;
            visibility: visible;
        }
    }
}

.iblefkh-spacer {
    &.api-center-login {
        width: 35px;
        height: 1px;
        @include xmedium() {
            display: none;
            visibility: hidden;
        }
    }
}

.nav__link--search {
    padding-top: 0;
    padding-bottom: 0;
    display: block;
}

.icon-search {
    margin-bottom: 0px;
}

.icon-logout {
    margin-bottom: -2px;
}

.nav__link--search {
    display: block;
}

.input-search {
    background-color: #212121;
    padding: 20px;
    color: white;
    opacity: 0;
    transition: $base-transition;

    &.input-search--active {
        opacity: 1;
        padding-right: 45px;
        max-width: 200px;
        line-height: 1.3;
        z-index: 980;
        position: relative;
        // width: 200px;
    }


    &::placeholder {
      color: white;
    }

}

.global-search__form {
    display: inline-block;
    position: absolute;
    right: 10px;
    top: -37px;
}

.nav__link--search,
.global-search__submit {
    @include medium {
        position: absolute;
        top: 0px;
        right: 0;
        border: none;
        padding: 0;
        fill: #ffffff;
        color: #ffffff;
        width: 50px;
        bottom: 0;
        cursor: pointer;
        z-index: 981;

        &:hover {
            opacity: 0.8;
        }
    }
}

.nav__link--search--hidden {
    display: none;
}



.global-search__submit {
    display: none;
    background: none;
}

.global-search__submit.global-search__submit--active {
    display: block;
}


.nav-header-mobile {
    padding: 15px 0;
    text-align: justify;
    text-justify: distribute-all-lines;
    height: 50px;

    > * {
        display: inline-block;
        vertical-align: middle;
        text-align: left;
    }

    &:after {
        display: inline-block;
        width: 100%;
        content: '';
    }

}

.icon-search--trigger {
    position: absolute;
    right: 15px;
    bottom: 22px;
}

.logout-button {
    background: none;
    border: none;
    color: #fff;
}

