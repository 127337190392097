
// -----------------------------------------------------------------------------
// Tables
// -----------------------------------------------------------------------------

.table {
    border: 1px solid #edeeee;
    border-radius: 7px;
    border-collapse:separate;
    overflow: hidden;
    display: table;

    th, .th {
        background: $color-api-centre-blue;
        text-transform: uppercase;
        color: white;
        font-size: 13px;
        font-weight: 700;
        padding: 17px;
        border-right: 1px solid rgba(#edeeee, .2);
        vertical-align: top;
    }

    td {
        font-size: 15px;
        padding: 14px 22px 18px;
        vertical-align: top;
        border-bottom: 1px solid #edeeee;
        border-right: 1px solid #edeeee;
        line-height: 22px;

        &:last-child {
            border-right: none;
        }
    }

    tr.heavy-bottom-border td{
        border-bottom-width: 3px;
    }

    tr.heavy-top-border td {
        border-top-width: 3px;
    }
}

.table--pdf--small {
    td {
        line-height: 17px;
    }
}

.table--print {
    border-collapse: collapse;
    margin-left: 10px;
    margin-right: 10px !important;
    margin-top: 10px;
    th {
        font-size: 9px;
        padding: 13px;
    }

    td {
        font-size: 10px;
        line-height: 14px;
        padding: 10px 10px 10px;

        &:last-child {
            border-right: 2px solid #edeeee;
        }

        .xsmall {
            font-size: 7px;
        }
    }

    border-bottom: 2px solid #edeeee;

    &.no-margin-left {
        margin-left: 0;
        td {
            padding-left: 0;
        }
        margin-bottom: 0;
    }

    &.no-borders {
        td {
            border: none !important;
        }
        border: none !important;
    }

    &.font-large {
        th {
            font-size: 12px;
        }

        td {
            font-size: 12px;
        }
    }
}

.table--plain {
    th {
        padding: 17px;
        vertical-align: top;
        border-bottom: 1px solid #edeeee;
        line-height: 1.2;

        @include narrow {
            padding: 10px 12px;
        }

        a {
            color: #777777;
            font-size: 12px;
            font-weight: 500;
        }

    }

    td {
        font-size: 15px;
        padding: 16px 18px 18px;
        vertical-align: top;
        border-bottom: 1px solid #edeeee;
        line-height: 22px;

        @include narrow {
            font-size: 12px;
            padding: 15px 12px;
        }

        &.column-narrow {
            width: 60px;
            padding-bottom: 0;
        }

    }

    .column-medium {
        width: 90px;
        padding-left: 15px;
        padding-right: 15px;

    }

}


.feature-text {
    font-size: 18px;
    color: #555555;

    @include narrow {
        font-size: 15px;
    }
}

.table--striped {
    tr {
        background-color: white;
    }
    tr:nth-child(even) {
        background-color: #fafcfd;
        .register-edit-icon {
            background-color: #fafcfd;
        }
    }
}


// -------------------------------------------------------------------
// Table Sticky Scrolling
// -------------------------------------------------------------------

.table-scroll {
    position: relative;
    width:100%;
    z-index: 1;
    margin: auto;
    overflow: auto;
}

.table-scroll table {
    width: 100%;
    margin: auto;
    border-collapse: separate;
    border-spacing: 0;
  }

.table-layout--fixed {
    table-layout: fixed;
}

.table-scroll thead th {
    @include medium {
        position: -webkit-sticky;
        position: sticky;
        top: 0;
    }
}

.table-scroll-column-sticky {
    @include medium {
        position: -webkit-sticky;
        position: sticky;
        left: 0;
        background-color: inherit;
    }
}

.table-scroll thead th:first-child {
    left: 0;
    z-index: 5;
}

.table-scroll tbody td:first-child {
    @include medium {
        position: -webkit-sticky;
        position: sticky;
        left: 0;
        z-index: 999;
        background-color: inherit;
        &.register-read-only-message {
            background-color: #F0F0F0;
        }
    }
}

// Don't know why this was here....yet... took it out because it was causing scrolling issues. CSS blows.
//.table-scroll td {
//    //vertical-align: unset !important;
//}

.table.table--compact {
  th, .th {
    font-size: 12px;
    line-height: 1.3;
    padding: 12px;
  }

  td {
    font-size: 13px;
    padding: 12px;
    white-space: pre;
    line-height: 20px;
  }
}


// -----------------------------------------------------------------------------
// Register tables
// -----------------------------------------------------------------------------

$register-table-cell-height: 60px;

.register-table-util-column {
    width: 65px;
    min-width: 65px;
}

.register-table--editable-cell,
.register-table--delete-cell {
    // we want the input to have the padding, not the cell itself.
    padding: 0 !important;
    height: $register-table-cell-height;
}

.register-table--header-cell {
    display: table-cell;

    &.th {
        @include narrow {
            position: relative !important;
        }
    }

    &.sticky {
        position: -webkit-sticky;
        position: sticky;
        top: 0;
        left: 0;
        z-index:800;
    }
}

.register-table--read-only-cell,
.register-table--generic-input {
    padding: 12px;
}

.register-table--read-only-cell {
    line-height: 32px;
}

.register-table--input-focus {
    & + svg {
        display: none;
        position: absolute;
        top: 37%;
        right: 0.5rem;
    }
    &:hover {
        & + svg {
            display: inline-block;
        }
    }
    &:hover {
        cursor: pointer;
    }
    &:disabled {
        & + svg {
            display: none;
        }
    }
}

.register-table--datetime-input {
    .rw-widget-container {
        border: none;
        background-color: inherit;
        height: 100%;
    }
    input {
        padding: 12px;
        color: #000;
        min-width: 162px;
        background-color: inherit;
        box-shadow: none;
        &:focus {
            box-shadow: none !important;
        }
    }
    .rw-widget-container:hover {
        box-shadow: none !important;
    }
    .rw-calendar-popup {
        width: 143%;
    }

    .rw-popup-container {
        left: -40%;
    }

    .rw-calendar-footer,
    .rw-calendar-header {
        background-color: white;
    }

    .rw-select-bordered {
        padding-left: 0.5rem;
        padding-right: 0.5rem;
        width: inherit !important;
        border-color: #edeeee;

        // These datetimepicker buttons are styled like this because IE11 is a jerk.
        .rw-btn {

            position: absolute;
            top: 0;
            right: 0;
            left: 0;
            bottom: 0;
            height: 60px;
            width: 50%;
            margin-left: 8%;
        }
        .rw-btn:last-child {
            margin-left: 47%;
        }
        border-left: none;
    }

    td {
        font-size: inherit !important;
        padding: 0 !important;
        white-space: inherit !important;
        line-height: inherit !important;
    }
}

.register-edit-icon {
    display: none;
    position: absolute;
    right: 0;
    pointer-events: none;
    padding-left: 5px;
    opacity: 0;
    background: #fff;
    @include xmedium {
        display: block;
    }
}

.register-table-cell:hover .register-edit-icon {
    opacity: 1;
}

.register-table-cell {
    &:hover {
        box-shadow: inset 0 -2px 0 #CACACA;
    }
}

.register-table--cell__error {
    input {
        border-bottom: 2px solid red;
    }
}

.register-table--cell__deleted,
.register-table--cell__deleted * {
   text-decoration: line-through;
}

.register-table--delete-cell {
    width: 65px;
    .icon-delete {
        position: absolute;
        left: -10px;
        top: 35%;
    }
}

.register-table--head-state {
    white-space: nowrap;
}

.register-filters-trigger {
    .icon-close-small {
        position: absolute;
        top: 8px;
        width: 13px;
        height: 13px;
    }
}

.register-filter-toggle-width {
    @include narrow {
        min-width: 40%;
        max-width: 40%;
    }
}

.register-filter-bar {
    height: 70px;
}

.register-filter-bar--fixed {
    position: fixed;
    top: 102px;
    z-index: 100;
    opacity: 1;
    background-color: white;
    @include narrow {
        top: 60px;
    }

}

.register-table-sticky-header {
    position: fixed;
    top:180px;
    z-index:9999;
}

.register-fixed-table-header {
    padding-bottom: 0 !important;
    @include narrow {
        padding-top: 0 !important;
    }
}

.register-table-pr--fixed {
    padding-right: 0.3rem;
}

.register-top-scroll-bar-outside-wrapper {
    padding-bottom: 0 !important;
    margin-bottom: -0.5rem;
}

.register-top-scroll-bar-wrapper {
    height: 20px;
    overflow-x: scroll;
    overflow-y: hidden;
    //&::-webkit-scrollbar {
    //  -webkit-appearance: none;
    //  width: 10px;
    //}
    //&::-webkit-scrollbar-thumb {
    //  border-radius: 5px;
    //  background-color: rgba(0,0,0,.5);
    //  -webkit-box-shadow: 0 0 1px rgba(255,255,255,.5);
    //}
}

.register-top-scroll-bar {
    height: 20px;
    overflow: auto;


}
