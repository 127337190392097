form.marketingForm *,
div.onFormSubmittedFeedback *,
.one-column-form * {
    font-family: Open Sans,sans-serif!important;
}

.topic-disabled-text{color:#a6a6a6;}

.marketingForm div[data-editorblocktype="Text"] span {
    font-size: 48px!important;
}

.marketingForm th {
    border-bottom: none!important;
}

.marketingForm .outer {
    width: 100%!important;
    display: table!important;
}

.marketingForm div[data-layout="true"],
.one-column-form {
    margin: unset!important;
    max-width: none!important;
}

.marketingForm input[type="checkbox"],
input.lp-form-fieldInput[type="checkbox"] {
    //background: #53c3af!important;
    //border-color: #53c3af!important;
    //border: 2px solid #979797!important;
    //border-radius: 4px!important;
    width: 26px!important;
    height: 26px!important;
    //box-shadow: 0 0 0 3px rgba(0,0,0,.12);
    margin-right: 10px!important;
}

input.lp-form-fieldInput[type="checkbox"] {
    box-sizing: border-box !important;
    appearance: auto !important;
    border: 1px solid #323130 !important;
    border-radius: 2px !important;
    clear: both !important;
}
.marketingForm .twoOptionFormFieldBlock label p {
 color: #909090!important;
    letter-spacing: .03em!important;
    font-weight: 600!important;
    font-size: medium!important;
}

.marketingForm .optionSetFormFieldBlock .block-label p {
    color: #333!important;
    letter-spacing: .03em!important;
    font-weight: 700!important;
    font-size: 20px!important;
    line-height: 1.3!important;
    margin: 25px 0px!important;
}

.marketingForm div[data-editorblocktype="TextAreaFormField"] label p {
    color: #333!important;
    letter-spacing: .03em!important;
    font-weight: 700!important;
    font-size: 20px!important;
    line-height: 1.3!important;
    text-transform: none!important;
    margin: 25px 0px!important;
}

.marketingForm .multiOptionSetFormFieldBlock .block-label p {
    color: #333!important;
    letter-spacing: .03em!important;
    font-weight: 700!important;
    font-size: 20px!important;
    line-height: 1.3!important;
    margin: 25px 0px!important;
}

.marketingForm .multiOptionSetFormFieldBlock fieldset label,
.lp-form-field .lp-ellipsis{
    color: #909090!important;
    letter-spacing: .03em!important;
    font-weight: 600!important;
    font-size: medium!important;
}

.marketingForm .textFormFieldBlock label p {
    text-transform: uppercase!important;
    color: #909090!important;
    letter-spacing: .03em!important;
    font-weight: 600!important;
    font-size: 12px!important;
    line-height: 1.6!important;
}

.marketingForm .textFormFieldBlock label {
    text-transform: uppercase!important;
    color: #909090!important;
    letter-spacing: .03em!important;
    font-weight: 600!important;
    font-size: 12px!important;
    line-height: 1.6!important;
}

.marketingForm .textFormFieldBlock input,
input.lp-form-fieldInput {
    display: block!important;
    width: 100%!important;
    height: 3em!important;
    padding: 16px 0!important;
    border: 0!important;
    border-radius: 0!important;
    font-weight: 400!important;
    line-height: 1!important;
    color: #212121!important;
    outline: 0!important;
    -webkit-appearance: none!important;
    -moz-appearance: none!important;
    appearance: none!important;
    border-bottom: 1px solid #ddd!important;
    resize: none!important;
    background: transparent!important;
    transition: all .2s ease-in-out!important;
}

.marketingForm .submitButton,
.lp-form-button {
    display: inline-block!important;
    vertical-align: middle!important;
    font: inherit!important;
    text-align: center!important;
    margin: 0!important;
    cursor: pointer!important;
    background-color: #254184!important;
    color: #fff!important;
    transition: all .1s ease-in!important;
    text-decoration: none!important;
    white-space: nowrap!important;
    overflow: hidden!important;
    text-overflow: ellipsis!important;
    font-size: 16px!important;
    font-weight: 700!important;
    line-height: 16px!important;
    padding: 14px 36px!important;
    border: none!important;
    border-radius: 999em!important;
    position: relative!important;
}

.lp-form-button {
    width: auto !important;
    height: auto !important;
}

.marketingForm .submitButton:hover,
.lp-form-button:hover {
    background-color: #00aeef!important;
    text-decoration: none!important;
    color: #fff!important;
}

.wrapperContainer.leftcolumn {
    width: auto !important;
    display: block !important ;
    margin-top: 30px !important;
}

.wrapperContainer.rightcolumn {
    width: auto !important;
    display: block !important ;
    margin-top: 15px !important;
}

//.ResetButtonBlock button.lp-form-button {
//
//}
