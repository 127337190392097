.breakout-stream {
  padding: 30px;

  &.dark {
    background-color: $color-payments-dark-grey;
  }

  &.light {
    background-color: $color-payments-light-blue-hex;
  }
}