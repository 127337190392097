

p {
    margin: 0;
    font-size: 18px;
    line-height: 1.7em;
}


.intro {
    font-size: 24px;
    line-height:  1.4em;

    @include narrow {
        font-size: 19px;
    }

}

.large {
    font-size: 24px;

    @include narrow {
        font-size: 18px;
    }

}

.xlarge {
  font-size: 30px;
}





small,
.small {
   font-size: 15px; /* 15px | 13.5px */
   display: block;
}

li.small {
    display: list-item;
}


.xsmall {
   font-size: 12px; /* 15px | 13.5px */
   color: $color-black-50;
}


