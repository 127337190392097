// -----------------------------------------------------------------------------
// Inline Block Grid
// -----------------------------------------------------------------------------

.grid {
    @include clearfix;
    margin:  0;
    padding: 0;
    list-style: none;
    font-size: 0;
}

.grid > * {
    display: inline-block;
    vertical-align: top;
    width: 100%;
    font-size: medium;
    text-justify: auto;
}


// -----------------------------------------------------------------------------
// Universal Grid spacing
// -----------------------------------------------------------------------------

.grid--row-spacing {
    margin-bottom: $spacing-grid;

    > * {
        margin-bottom: $spacing-grid;
    }
}

.grid--row-spacing--large {
    margin-bottom: $spacing-grid * 2;

    > * {
        margin-bottom: $spacing-grid * 2;
    }
}

.grid--col-spacing {
    margin-left: -$spacing-grid;

    > * {
        padding-left: $spacing-grid;
    }
}

.grid--col-spacing--large {
    margin-left: -$spacing-grid * 2;

    > * {
        padding-left: $spacing-grid * 2;
    }

}


.grid--col-spacing--xlarge {
    margin-left: -$spacing-grid * 4;

    > * {
        padding-left: $spacing-grid * 4;
    }

}




// -----------------------------------------------------------------------------
// Grid item alignment modifiers - they are top aligned by default
// -----------------------------------------------------------------------------

.grid--middle > * {
    vertical-align: middle;
}

.grid--bottom > * {
    vertical-align: bottom;
}


// -----------------------------------------------------------------------------
// Justify grid - push items apart from each other
// -----------------------------------------------------------------------------

.grid--justified,
.grid--polar {
    text-align: justify;
    text-justify: distribute-all-lines;

    > * {
        text-align: left;
    }

    &:after {
        display: inline-block;
        width: 100%;
        content: '';
    }
}



// Dev Helper to show column placement. add debug class to grid.
body.develop .grid--debug {
    outline:2px dashed #2c3e50;
    & > * {
        outline:2px dashed #c0392b;
    }
}


// grid overlay
body.develop .grid-overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0; bottom: 0; left: 0; right: 0;
    .grid {
        outline:none!important;
        & > * {
            outline:none!important;
            span {
                height: 100%;
                display: block;
                background-color: rgba(0,0,0,0.1);
                outline: 1px dashed orange;
            }
        }
    }
}
