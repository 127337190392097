
// -----------------------------------------------------------------------------
// COMPONENTS
// Used on the Payments NZ homepage to advertsie the API Centre
// -----------------------------------------------------------------------------


.api-banner {
    background-image: linear-gradient(-90deg, #008AE8  0%, #00AEEF 99%);
    color: #fff;

    a {
        color: inherit;
    }

}

.tag {
    background-color: rgba(#fff, .2);
    display: inline-block;
    padding: 5px 12px;
    border-radius: 5px;
    font-size: 11px;

    @include medium {
        font-size: 14px;
    }

}

.api-banner__content-wrapper {
    margin-top: -24px;
    @include medium {
        margin-top: 0;
    }
}

.api-banner__icon {
    margin-right: 12px;
    width: 15px;
    height: 13px;

    @include medium {
        width: 49px;
        height: 41px;
    }
}

.api-banner__title {
    font-family: 'Gilroy-SemiBold', Helvetica, Arial, sans-serif;
    font-size: 18px;
    margin: -5px 0 12px;
    line-height: 22px;

    @include medium {
        font-size: 36px;
        margin: -2px 0 12px;
        line-height: 40px;
    }

}

.api-banner__btn {
    font-size: 14px;
     @include medium {
        font-size: 18px;
     }
}

.api-grid > div  {

    @include medium {
        padding-left: 3rem;
    }

}

.api-banner__padding {
    padding-left: 3em;
    padding-top: 30px;
    padding-bottom: 20px;


    @include medium {
        padding-left: 2.5em;
        padding-right: 2.5em;
        padding-bottom: 0;
    }

    @include large {
        padding-left: 6.5em !important;
        padding-right: 6.5em !important;
        padding-top: 2.5em;
    }

}

.api-logo {
    margin-top: -40px;
    max-width: 144px;

    @include medium {
        margin-top: -8px;
        max-width: 100%;
    }

}

