// convert pixel to em
@function em($size, $em-base: 16) {
    $em-size: $size / $em-base;
    @return #{$em-size}em;
}

// convert pixel to rem
@function rem($size) {
    $rem-size: strip-unit($size) / strip-unit($base-font-size);
    @return #{$rem-size}rem;
}

// Remove the unit
@function strip-unit($number) {
    @if type-of($number) == 'number' and not unitless($number) {
        @return $number / ($number * 0 + 1);
    }

    @return $number;
}