// --------------------------------------------------
// Buttons
// -------------------------------------------------

.button-reset {
    -webkit-appearance: none;
    border: none;
    background: transparent;
}

.btn {
    display: inline-block;              // [1]
    vertical-align: middle;             // [2]
    font: inherit;                      // [3]
    text-align: center;                 // [4]
    margin: 0;                          // [4]
    cursor: pointer;                    // [5]
    background-color: #254184;
    color: #fff;
    transition: $base-transition;
    text-decoration: none;

    white-space: nowrap;                // [7]
    overflow: hidden;
    text-overflow: ellipsis;

    font-size: 16px;
    font-weight: 700;
    line-height: 16px;
    padding: 14px 25px;

    border: none;
    border-radius: 999em;
    position: relative;

    @include small {
        max-width: 100%;
    }

    &:hover {
        background-color: #00AEEF;
        text-decoration: none;         // [9]
        color: white;
    }


    &[disabled],
    &.is-disabled {
        pointer-events: none;
        cursor: default;
        // opacity: .5 !important;
        background-color: #D0D0D0;
    }

    &.inverted {
        background-color: #00AEEF;
        &:hover {
            background-color: #254184;
        }
    }

    &.is-blue {
        background-color: #0079CF;

        svg {
            fill: $color-white;
            color: $color-white;
            margin-left: rem(10px);
        }

        &:hover {
            background-color: $color-payments-dark-blue;
        }
    }

    &.is-white {
        background-color: $color-white;
        color: $color-black;

        svg {
            fill: $color-black;
            color: $color-black;
            margin-left: rem(10px);
        }

        &:hover {
            background-color: $color-payments-light-blue;
        }
    }
}


.btn > .icon {
    margin-left: 20px;
}

// Size modifiers
// ----------------------------------------------------------------------------

.btn--small {
    font-size: 15px;
    line-height: 14px;
    padding: 10px 18px;
    font-weight: normal;
}


.btn--h6 {
    padding: 14px 35px 13px;
    font-size: 12px;
    text-transform: uppercase;
    font-weight: 700;

    > span {

        // top: -3px;
        color: #ffffff;
    }

    > svg {
        position: absolute;
        left: 17px;
        fill: #fff;
        color: #fff;
    }

    &:hover {
        background-color: white;
        color: #363636;

        > span {
            color: #363636;
        }

        svg {
            fill: #363636;
            color: #363636;
        }

    }

}


// .btn--large {
//     font-size: 25px;
//     line-height: 1.25;
//     padding: 15px 25px;

// }


// Colors modifiers
// ----------------------------------------------------------------------------




.btn--primary--dark {
    background-color: #333333;
    color: white;

    &:hover {
        background-color: #254184;
    }

}

.btn--secondary--white {
    background-color: #fff;
    color: #254184;
    border: 1px solid #254184;

   &:hover {
       background-color: #254184;
       color: white;
   }


   &.-grey {
        background-color: #fff;
        color: #333333;
        border: 1px solid #E7E7E7;
   }
}

/* used on homepage api centre banner only */
.btn--secondary--yellow {
    font-family: 'Gilroy-SemiBold', Helvetica, Arial, sans-serif;
    background-color: $color-payments-yellow;
    color: #000;

   &:hover {
       background-color: #254184;
       color: white;
   }
}

.btn--secondary--small {
    padding: 8px 14px;
    font-size: 14px;
    svg {
        pointer-events: none;
    }
    &:hover {

        svg path {
            color: $color-white;
            fill: $color-white;
        }
    }

}

.btn--secondary--small--white {
    background-color: #fff;
    color: #333333;
    padding: 8px 14px;
    font-size: 14px;
    font-weight: 600;
    svg {
        pointer-events: none;
    }
    &:hover {
        background-color: white;
        color: #333333;
        svg path {
            color: $color-white;
            fill: $color-white;
        }
    }

}



.btn--hollow--white {
    background-color: transparent;
    color: white;
    border: 1px solid white;
}


// .btn--red {
//     background-color: $color-penny-red;
//     color: white;

//     &:hover {
//         background-color: darken($color-penny-red, 10%);
//     }

//     &.inverted {
//         background-color: rgba($color-penny-red, 0.1);
//         color: $color-text-red;

//         &:hover {
//             background-color: rgba($color-penny-red, 0.2);
//         }

//     }

// }



// .btn--green {
//     background-color: $color-go-green;
//     color: white;

//     &:hover {
//         background-color: darken($color-go-green, 10%);
//     }

//     &.inverted {
//         background-color: rgba($color-go-green, 0.1);
//         color: $color-text-green;

//         &:hover {
//             background-color: rgba($color-go-green, 0.2);
//         }

//     }

// }






// Btn group
// ----------------------------------------------------------------------------


.btn--group {
    > .btn {
        display: inline-block;
        margin: 0 10px;
    }
}


.btn--group-stack {
    > .btn {
        display: block;
        margin: 50px 0 0;
    }
}


.btn--block {
    display: block;
}

.btn--full {
    width: 100%;
}

.btn--group .btn {
    margin: 50px auto 0;
}



.btn--download {
    padding-right: 40px;
    position: relative;

    .icon-download {
        position: absolute;
        right: 20px;
        top: 7px;
    }

    &:hover .icon-download {
        color: white;
    }

}

.list-inline__item {
    .icon-settings {
        color: #333;
    }
}

.cursor-pointer {
    cursor: pointer;
}
