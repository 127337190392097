
// Main
fieldset {
    margin: 0;
    padding: 0;
    border: 0;
}

input, textarea, select, button {
    font-family: 'Open Sans',sans-serif;
}


// -----------------------------------------------------------------------------
// form label
// -----------------------------------------------------------------------------

.form-label {
    // width: 300px;
    margin: 0 auto;

    fieldset {
        position: relative;
    }

}


.input {
    display: block;
    width: 100%;
    height: 3em;
    padding: 16px 0;
    border: 0;
    border-radius: 0;
    font-weight: 400;
    line-height: 1;
    color: #212121;
    outline: 0;
    appearance: none;
    transition: all 0.2s ease-in-out;
    border-bottom: 1px solid #ddd;
}

.label {
    position: absolute;
    top: 10px;
    left: 0;
    color: #909090;
    transform: translate3d(0, 0, 0);
    transition: all 0.2s ease-in-out;
    letter-spacing: 0.03em;
    font-weight: 600;

    &.pre-positioned {
        top: -10px;
    }
}


// -----------------------------------------------------------------------------
// custom input
// -----------------------------------------------------------------------------

.input {
    &.label {
        padding: 22px 0 10px;
    }
    &:focus,
    &.--focus {
        outline: 0;
        border-bottom: 1px solid #00A3DA;
    }

    &.locked {
        background-image: url('/static/images/padlock.svg');
        background-repeat: no-repeat;
        background-position-x: right;
        background-size: 20px;
        background-position-y: center;

        opacity: 0.6;
    }
}

.active-label  {
    text-transform: uppercase;
    font-size: 12px;
    transform: translate3d(0, -20px, 0)!important;
}

.input--required {
    position: absolute;
    top: -10px;
    right: 0;
    font-size: 18px;
    font-weight: normal;

    &.pre-positioned {
        top: -30px;
    }

}

.input--required--radio {
    font-weight: normal;
    font-size: 18px;
    margin-left: 0.5rem;
}

.label-helper {
    margin-top: 5px
}

textarea {
    background-color: transparent;
}

// -----------------------------------------------------------------------------
// textarea
// -----------------------------------------------------------------------------

.textarea {
    // Always want the textarea to be 100% of it's parent
    // Use the parent to control absolute width
    width: 100%;
}

// -----------------------------------------------------------------------------
// checkbox with label
// -----------------------------------------------------------------------------

.checkbox {
    position: absolute; // take it out of document flow
    opacity: 0; // hide it

    & + label,
    .checkbox-label {
        position: relative;
        cursor: pointer;
        padding: 0 0 0 40px;
        display: inline-block;
    }

    // Box.
    & + label:before,
    .checkbox-label:before {
        content: '';
        margin-right: 10px;
        display: inline-block;
        vertical-align: text-top;
        width: 26px;
        height: 26px;
        background: white;
        // margin-top: -4px;
        transition: $base-transition;
        border: 2px solid #979797;
        border-radius: 4px;
        position: absolute;
        top: 0;
        left: 0;
    }

    // Box hover
    &:hover + label:before {
        background: #53C3AF;
        border-color: #53C3AF;
    }

    // Box focus
    &:focus + label:before {
        box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.12);
    }

    // Box checked
    &:checked + label:before {
        background: #53C3AF;
        border-color: #53C3AF;
    }

    // Disabled state label.
    &:disabled + label {
        color: #b8b8b8;
        cursor: auto;
    }

    // Disabled box.
    &:disabled + label:before {
        box-shadow: none;
        background: #ddd;
    }

    // Checkmark. Could be replaced with an image
    &:checked + label:after {
        content: '';
        position: absolute;
        left: 8px;
        top: 12px;
        background: white;
        width: 2px;
        height: 2px;
        box-shadow:
          2px 0 0 white,
          4px 0 0 white,
          4px -2px 0 white,
          4px -4px 0 white,
          4px -6px 0 white,
          4px -8px 0 white;
        transform: rotate(45deg);
    }

}




// -----------------------------------------------------------------------------
// checkbox without label
// -----------------------------------------------------------------------------

.custom-checkbox {
    width: 20px;
    height: 20px;
    position: relative;

    > * {
        position: absolute;
    }

    &-visible {
        width: 26px;
        height: 26px;
        background: white;
        margin-top: -4px;
        transition: $base-transition;
        border: 2px solid #979797;
        border-radius: 4px;
    }

    > input {
        z-index: 1;
        opacity: 0;
        left: 50%;
        top: 50%;
        transform: translatex(-50%) translatey(-50%);
        display: block;
        cursor: pointer;
        width: 20px;
        height: 20px;
    }

    > input:checked + .custom-checkbox-visible {
        background: #53C3AF;
        border-color: #53C3AF;
        position: relative;

        &:after {
            content: '';
            position: absolute;
            left: 6px;
            top: 10px;
            background: white;
            width: 2px;
            height: 2px;
            box-shadow:
              2px 0 0 white,
              4px 0 0 white,
              4px -2px 0 white,
              4px -4px 0 white,
              4px -6px 0 white,
              4px -8px 0 white;
            transform: rotate(45deg);
        }

    }

    > input:hover + .custom-checkbox-visible {
        background: #53C3AF;
        border-color: #53C3AF;
    }

    > input:hover:checked + .custom-checkbox-visible {
        background: #53C3AF;
        border-color: #53C3AF;
    }

    > input:focus + .custom-checkbox-visible {
        box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.12);
    }


    .column-narrow & {
        margin-top: 3px;
    }

}






// -----------------------------------------------------------------------------
// radio
// -----------------------------------------------------------------------------

.radio {
    position: absolute; // take it out of document flow
    opacity: 0; // hide it

    & + label {
      position: relative;
      cursor: pointer;
      padding: 0;
    }

    // Box.
    & + label:before {
        content: '';
        margin-right: 10px;
        display: inline-block;
        vertical-align: text-top;
        width: 25px;
        height: 25px;
        background: white;
        margin-top: 0;
        transition: $base-transition;
        border: 2px solid #979797;
        border-radius: 30px;
    }

    // Box hover
    &:hover + label:before {
      background: #53C3AF;
      padding: 2px;
      border-color: #53C3AF;

    }

    // Box focus
    &:focus + label:before {
      box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.12);
    }

    // Box checked
    &:checked + label:before {
        background: #53C3AF;
        border-color: #53C3AF;
        width: 18px;
        height: 18px;
        margin-right: 15px;
        margin-left: 2px;
        margin-top: 2px;
        box-shadow:
            0 0 0 4px #ffffff,
            0 0 0 6px #53c3af;
    }

    & + .radio-block-label {
        display: block;
        padding: 2rem;
        border: 7px solid #ddd;

        &:hover {
            border: 7px solid #53C3AF;
        }

        p {
            font-size: 15px;
        }

        .icon-tick {
            display: none;
        }

        h3 {
            display: inline-block;
        }

    }

    // & + .radio-block-label:before {
    //     //display: none;
    // }

    &:checked + .radio-block-label {
        border: 7px solid #53C3AF;

        .icon-tick {
            position: absolute;
            right: 2rem;
            top: 41px;
            display: block;
        }

    }

}


.radio-group-container {
    display: inline-block;
    @include narrow {
        display: block;
        margin-bottom: 1rem;
        label {
            margin-left: 0 !important;
        }
    }
}

// -----------------------------------------------------------------------------
// Select
// -----------------------------------------------------------------------------

.select-style {
    display: block;
    width: 100%;
    height: 3em;
    padding: 16px 30px 16px 0;
    border: 0;
    border-radius: 0;
    font-weight: 400;
    line-height: 1;
    color: #212121;
    outline: 0;
    appearance: none;
    transition: all 0.2s ease-in-out;
    border-bottom: 1px solid #ddd;
    position: relative;

    .select-icon {
        position: absolute;
        transform: translate(50%, 50%);
        right: 10px;
        top: 50%;
        pointer-events: none;
    }

    select {
        padding: 5px 8px;
        width: 130%;
        border: none;
        box-shadow: none;
        background-color: transparent;
        background-image: none;
        -webkit-appearance: none;
           -moz-appearance: none;
                appearance: none;

        @include narrow {
            width: 110%;
        }

        &:focus {
            outline: none;
        }
    }

    &.select-style-registers {
        border-bottom: none;
        height: 100%;
        .select-icon {
            right: 24px;
            top: 35%;
        }

        select {
            width: 109%; // Allows overlap of the chevron
        }

    }

}

.select-multiple {
    border: 1px solid #ddd;
    width: 100%;
}


.select-list {

    .select-style {
        font-size: 14px;
        text-transform: uppercase;
        border-bottom: none;
        font-weight: 700;
    }

    select {
        text-transform: uppercase;
        font-weight: 700;
    }

}

.select-style-stats-institute {

    select {
        font-size: 14px;
        color: rgb(85,85,85);
        font-weight: 600;
    }
}





.fieldset--success {
    .input {
        border-bottom-color: #53C3AF;
    }
}

.fieldset--error {
    .input {
        border-bottom-color: #FE7C67;
    }
    .rw-widget-container, .radio-block-label, .select-style {
        border-color: #FE7C67;
    }
    //.select-style {
    //    border-color: #FE7C67;
    //}

}


.fieldset--success,
.fieldset--error {
    .icon {
        position: absolute;
        transform: translate(50%, 50%);
        left: -25px;
        top: 18px;
        height: 9px;
        width: 9px;
    }
}

#contact-form {


    .control-label {
        font-size: 22px;
        transform: translate3d(0, 12px, 0) scale(1);
    }

    .form-group.focused .control-label {
        transform: scale(0.55);
    }

}


.file-input-label {
    display: block;
}


.icon-breadcrumbs {
    width: 28px;
    height: 23px;
}


