
@font-face {
  font-family: 'RockwellLight';
  src: url('../../fonts/340f9982-e8f7-4fa5-95eb-c8c1f6ecf402.woff2') format('woff2'),
       url('../../fonts/7658dda6-217a-406c-bcb8-7551f88e2c49.woff') format('woff'),
       url('../../fonts/ec796902-2e28-46d0-8bbd-071b83705a39.ttf') format('truetype');
}

@font-face {
    font-family: 'Hepta-Slab-Light';
    src: url('../../fonts/HeptaSlab-Light.ttf') format('truetype');
}

@font-face {
    font-family: 'Gilroy-SemiBold';
    src: url('../../fonts/395329_0_0.woff2') format('woff2'),
        url('../../fonts/395329_0_0.woff') format('woff'),
        url('../../fonts/395329_0_0.ttf') format('truetype');
}

h1, h2 {
    font-family: 'Hepta-Slab-Light';
    letter-spacing: -0.5px;
}

h2 {
    max-width: 75%;
}

.h1--print, .h2--print {
    font-family: 'Open Sans', sans-serif;
}

.h1--print {
    color: #fff;
    font-size: 20px;
    max-width: none;
}

.h5--print {
    margin-top: 0 !important;
    text-transform: none;
}

.h6--print {
    font-family: 'Open Sans', sans-serif;
    color: #fff;
    font-size: 10px;
    max-width: none;
    text-transform: none;
}


@import 'settings/index'; // Start here, add font sizes,  base
@import 'tools/index';
@import 'generic/index';
@import 'elements/index';
@import 'objects/index';
@import 'components/index';
@import 'themes/index';

// Most styles ought to be declared before the utilities, so utils can be used as an override. Slowly moving
// styles up this way as they're needed.
.icon-arrow-link {
    fill: #0079CF;
    color: #0079CF;
}

@import 'utilities/index';
@import 'lib/react_widgets';
@import 'lib/react_month_picker';
@import 'lib/fancybox';


// -----------------------------------------------------------------------------
// All this can be deleted - remove any example classes from html
// -----------------------------------------------------------------------------

.main-content {
    position: relative;
    background-color: #f9fbfc;
    // padding-bottom: 60px;

    @include large {
        padding-left: 240px;
    }

    // > div:last-child:not(.nav-lower) {
    //     // padding-bottom: 160px;
    // }

}

.no-side-nav {
    padding-left: 0!important;

}


@-webkit-keyframes pulse {
      0% {background-color: #FB263D;}
     25% {background-color: #FF7382;}
     50% {background-color: #FF273E;}
     75% {background-color: #7F3A41;}
     100% {background-color: #CC1F32;}
}

.pulse-bg {
    background-color: #45CEEF;
    animation: pulse 40s infinite alternate;
    color: white;
}

.grid-container-color-example {
    background: rgba(black, 0.1);
}

.wrapper-color-example {
    background: rgba(yellow, 0.2);
}

.grid-color-example {
    background: rgba(red, 0.3);
    padding: 50px 5px;
    text-align: center;
}

// .section {}






// -----------------------------------------------------------------------------
// TILES
// -----------------------------------------------------------------------------

.tile {
    background: #FFFEFD;
    border-radius: 4px;
    padding: 30px 40px 30px;
    position: relative;
    overflow: hidden;
    box-shadow: 0 1px 2px 0 rgba(0,0,0,0.25);

    @include medium {
        padding-bottom: 65px;
        &.tile--standard-padding {
            padding-bottom: 30px;
        }
    }

    // margin-top: 40px;

    p {
        font-size: 15px;
        line-height: 22px;
    }


    > h4 + p {
        margin-top: 10px;
    }

    > p + a {
        display: inline-block;
        margin-top: 10px;

    }

}

.tile--small {

    @include medium {
        padding-bottom: 20px;
    }

    .tile__link {
        margin-top: 25px;
        position: relative;
        bottom: 0;
    }

    .tile__bottom-right {
        bottom: 32px;
    }

}


.tile--large {
    min-height: 100%;
    margin-bottom: 20px;
    align-self: stretch;
    margin-bottom: 25px;
    padding-bottom: 0!important;

    @include large {
        margin: 0 20px 25px 0;
    }

}


/*
    Tile homepage
*/

.tile--homepage {
    @include large {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        min-height: initial!important;
        margin-bottom: 0;
    }
}



/*
    Tile with image on right
*/

.tile--image-right {
    padding-right: 0;
    padding-bottom: 0;
    padding-top: 0;
    padding-bottom: 1.5em;

    p {
        font-size: 18px;
        line-height: 29px;
    }

}

.tile__right-col {
    display: none;

    @include medium {
        display: block;
        max-width: 45%;
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
        background-color: #eee;
        background-image: url('../../images/_holder-homepage-tile.png');
        background-repeat: no-repeat;
        background-position: 50% 50%;
        background-size: cover;
        left: 65%;
    }
}

.tile__left-col {
    padding-top: 30px;
    float: left;
    padding-right: 30px;
    padding-bottom: 80px;

    @include medium {
        max-width: 55%;
    }
}



.tile-document {
  width: calc(33% - 27px); // Subtract left padding off
}

.tile-register-document {
    max-width:100%;
    @include xxlarge {
        max-width: 45%;
    }
}

.tile-register-document-three-col {
    max-width:100%;
    @include bp768 {
        width: 33%;
        max-width: 420px;
    }
}

@media only screen and (max-width: 750px) {
  .tile-document {
    margin-top: 20px;
    width: 100%;
  }

  .tile__bottom-right {
    position: absolute;
    right: 30px;
  }

  .subpage--header {
    width: 100%;
  }
}


$color-green: #07A485;
$color-orange : #F78234;
$color-red: #EB4E3F;
$color-yellow: #FFCF32;
$color-teal: #00BC90;
$color-purple: #78438E;
$color-forest-green: #5AB45B;
$color-turquoise: #33BCCE;
$color-blue: #00A3DA;
$color-grey: #BFBFBF;

.tile--feature-value {
    background-color: $color-payments-dark-blue;
    color: $color-white;

    p {
        text-align: center;
        padding-left: 40px;
        padding-right: 40px;
    }

    @include narrow {
        p {
            padding-left: 5px;
            padding-right: 5px;
        }
    }

    @include medium {
        padding: 30px 20px 30px;
        p {
            padding-left: 20px;
            padding-right: 20px;

        }
    }

    @include large {
        p {
            padding-left: 40px;
            padding-right: 40px;
        }
    }

    .feature-value {
        text-align: center;

        .feature-value--value {
            font-weight: 700;
        }

        .feature-value--symbol {
            vertical-align: top;
        }


        @include narrow {
            .feature-value--value {
                font-size: 50px;
            }
            .feature-value--scale, .feature-value--symbol {
                font-size: 25px;
            }
            .feature-value--symbol {
                line-height: 66px;
            }
        }

        @include medium {
            .feature-value--value {
                font-size: 75px;
            }
            .feature-value--scale, .feature-value--symbol {
                font-size: 40px;
            }
            .feature-value--symbol {
                line-height: 102px;
            }
        }


        @include xlarge {
            .feature-value--value {
                font-size: 100px;
            }

            .feature-value--symbol {
                font-size: 60px;
                line-height: 132px;

            }

            .feature-value--scale {
                font-size: 60px;
            }
        }

    }
}

.tile--blue {
    .tile__key {
        background-color: $color-blue!important;
    }
}

.tile--green {
    .tile__key {
        background-color: $color-green!important;
    }
}

.tile--teal {
    .tile__key {
        background-color: $color-teal!important;
    }
}

.tile--orange {
    .tile__key {
        background-color: $color-orange!important;
    }
}


.tile--red {
    .tile__key {
        background-color: $color-red!important;
    }
}

.tile--yellow {
    .tile__key {
        background-color: $color-yellow!important;
    }
}

.tile--purple {
    .tile__key {
        background-color: $color-purple!important;
    }
}

.tile--forest-green {
    .tile__key {
        background-color: $color-forest-green!important;
    }
}

.tile--turquoise {
    .tile__key {
        background-color: $color-turquoise!important;
    }
}

.tile--grey {
    .tile__key {
        background-color: $color-grey !important;
    }
}

.tile__link {
  display: inline-block;
}




.tile--image-top {
    padding: 0;
    width: 50%;

    @include narrow {
        width: 100%;
    }

    @include tiles-medium {
        width: 100%;
    }



}

.tile--image-top__image {
    width: 100%;
    height: 200px;
    background-size: cover;
    background-color: #ccc;
    background-position: 50% 50%;
    position: relative;
    z-index: 9;

    a {
        display: block;
        height:100%;
    }

    // @include narrow {
    //     background-color: #fffefd;
    //     background-size: 75%;
    //     background-repeat: no-repeat;
    // }

}

.tile__inner {
    padding: 30px 40px 30px;
    position: relative;
    height: calc(100% - 200px);
    padding-bottom: 65px!important;
}

.tile__inner--pb0 {
    padding-bottom: 0;
}

.tile__key {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 4px;
    background-color: #ccc;
}


.tile__icon {
    position: absolute;
    right: 20px;
    top: 34px;
    fill: #666!important;
    color: #666!important;
}

.tile__icon-svg {
    width: 15px;
    height: 17px;
}

// icon specific overrides
.tile__icon-svg.icon-community-tile {
    width: 22px;
    height: 18px;
}

.tile__bottom-right {
    //position: absolute;
    //right: 20px;
    //bottom: 30px;
    color: #777777;
    font-size: 12px;
    display: inline-block;
}


// Tile modifiers

.tile__key--blue {
    background-color: $color-api-centre-blue;
}

.tile__key--green {
    background-color: #49C2AC;
}

.tile__key--yellow {
    background-color: #FFCF32;
}

.tile__key--teal {
    background-color: #00BC90;
}

.tile__key--light-blue {
    background-color: $color-api-centre-blue;
}

.tile__key--red {
    background-color: $color-payments-red;
}

.tile__key--grey {
    background-color: #777777;
}

.tile__key--purple {
    background-color: #78438E;
}

.tile__key--green-forest {
    background-color: #78C675;
}





$text-link-color: #0079CF;







.secondary-link,
.tile__link {
    font-size: 14px;
    font-weight: 600;
    display: inline-block;
    margin-top: 25px;

    @include medium {
        position: absolute;
        bottom: 25px;
        margin-top: 0;
    }

    &:after {
        @include large {
            content: '';
            display: block;
            height: 3px;
            width: 0;
            background: transparent;
            transition: width .5s ease, background-color .5s ease;
        }
    }

    &:hover {
        color: #005D8A;

        .icon-arrow-link {
            fill: #0079CF;
            color: #0079CF;
        }


        &:after {
            width: 100%;
            background: #0079CF;
        }

    }

}

.file-size {
    right: 20px;
    bottom: 25px;
    position: absolute;
    font-size: 14px;
}






// -----------------------------------------------------------------------------
// FULL BLEED BACKGROUND COLORS
// -----------------------------------------------------------------------------


.full-bleed--off-white {
    padding-top: 80px;
    padding-bottom: 40px;
    background-color: #F4F5F5;

    //.full-bleed--off-white--graphic
    // i don't think this is used and can be removed.
    &.--graphic {
        //background-image: url('../../images/payments-mark.png');
        background-repeat: no-repeat;
        background-position: 100% 100%;
    }
}

.full-bleed--white {
    background-color: white;
}


.full-bleed--tabs {
    background-color: #f9fbfd;
}


.full-bleed--contact {
    background-color: #f4f6f7;
}

.full-bleed--padded {
    padding-top: 75px;
    padding-bottom: 75px;
}



// -----------------------------------------------------------------------------
// GENERIC SPACING
// -----------------------------------------------------------------------------

$spacing25:     25px;
$spacing50:     50px;
$spacing75:     75px;
$spacing100:    100px;

.spacing-2 {
    margin-top: 2px;
}

.spacing-5 {
    margin-top: 5px;
}

.spacing-10 {
    margin-top: 10px;
}

.spacing-20 {
    margin-top: 20px;
}

.spacing-30 {
    margin-top: 30px;
}

.spacing-25.spacing-25 {
    margin-top: $spacing25!important;
}

.spacing-50.spacing-50 {
    margin-top: $spacing50!important;
}

.spacing-75.spacing-75 {
    margin-top: $spacing75!important;
}

.spacing-100.spacing-100 {
    margin-top: $spacing100!important;
}





// headers

h1 + .intro {
    margin-top: 15px;
}

h2 + * {
    margin-top: 30px;
}


h3 + * {
    margin-top: 20px;
}

h2 + .large,
h3 + .large {
    margin-top: 10px;
}


h3 + h6 {
    margin-top: 5px;
}


h3 + p {
    margin-top: 10px;
}

h4 + p {
    margin-top: 15px;
}

h5 + .grid--tiles {
    margin-top: 100px;
}

h6 + p {
    margin-top: 30px;
}

h6 + .stats-feature-value {
    margin-top: 10px;
}

// p tags
.intro + * {
    margin-top: 30px;
}



p {

    + h3 {
        margin-top: 40px;
    }

    + h4 {
        margin-top: $spacing25;
    }

    + p {
        margin-top: 15px;
    }

    + ul {
        margin-top: $spacing25;
    }

    + .small {
        margin-top: 10px;;
    }
}

//lists
ul + *,
ol + * {
    margin-top: 35px;
}

* + .xsmall {
    margin-top: 10px;
}


// media blocks
* + .media {
    margin-top: 50px;
}

.media + .media {
    margin-top: 35px;
}


h1:first-child,
h2:first-child,
h3:first-child,
h4:first-child,
h5:first-child {
    margin-top: 0!important;
}


table + * {
    margin-top: 50px;
}

.icon-show-more {
    width: 6px;
    height: 20px;
}








// -----------------------------------------------------------------------------
//
// -----------------------------------------------------------------------------


.banner-feature {
    padding: 180px 0 90px;
}


.banner {
    background: #FFFFFF;
    box-shadow: 0 1px 3px 0 rgba(0,0,0,0.15);
    position: relative;
    z-index: 9;
}


.banner--padded {

    @include narrow {
        padding: 110px 0 70px;
    }

    padding: 180px 0 150px;
}

.banner__image {
    background-image: url('../../images/bg-texture.png');
    background-repeat: no-repeat;
    background-position: 100% 100%;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    // right: 0;
    width: 240px;

    @include narrow {
        display: none;
    }
}

.banner-small {
    background: #FFFFFF;
    padding: 3em 0;

    &.banner-image {
        //background-image: provided in app/app/templates/partials/sub_page_header.html template
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center right;

        // Reverse white text on dark background for StandardPage main banner (with background image)
        &.white-text {
            background-color: #000000; // fallback background to make text readable if banner image missing
            color: #FFFFFF;
            h1 {
                color: #FFFFFF;
            }
        }
    }
}

.banner-xsmall {
    padding: 2em 0;
}

.border-left {
    border-left: 4px solid #ccc;

    &.--green,
    .green & {
        border-left-color: green;
    }

    &.--red,
    .red & {
        border-left-color: #E6615A;
    }

}

.conference-speakers-page {
    .rich-text + * {
        margin-top: 0;
    }
}


.banner--conference--image-only-banner--wrapper {
    &.light {
        background-color: #f9fbfc;
    }
    &.dark {
        background-color: #000000;
    }

    .banner--conference--image-only-banner--container {
        max-width: 1440px;
        margin: 0 auto;

        &.homepage {
            max-width: 1118px; // to match the existing wrappers for content below
        }

        .banner--conference--image-only-banner {
            img {
                margin: 0 auto;
                width: 100%;
            }
            .large-img {
                display: inline-block;
                visibility: visible;
            }
            .mobile-img {
                display: none;
                visibility: hidden;
            }

            @media only screen and (max-width: 769px) { // mobile
                .large-img {
                    display: none;
                    visibility: hidden;
                }
                .mobile-img {
                    display: inline-block;
                    visibility: visible;
                }
            }
        }
    }
}

.banner--conference {
    padding-top: 8em;
    padding-bottom: 8em;
    color: white;


    &.border-left {
        border-left: none;
    }


    h1 {
        display: none;
    }

    svg {
        height: 69px;
        margin-bottom: 20px;

        @include narrow {
            width: 75%;
            height: 75%;
            margin-bottom: 10px;
        }
    }

    .banner--conference--logo {
        width: 420px;
        max-width: 100%;
    }

    .banner--conference--text {
        font-size: rem(20px);
    }

    &.banner--conference--homepage {
        background-color: #000034;
        padding: 0;

        svg {
            margin-left: -5px;
        }

        a {
            color: $color-white;
            display: block;
            &.action {
                display: inline-block;
            }

            &:hover {
                opacity: 0.6;

            }
            transition: opacity .5s ease
        }

        .wrapper {
            background-image: url('../../images/Conference-2024-Home-Page-Banner.png');
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;
            background-clip: padding-box;
            padding-top: 0;
            padding-bottom: 0;

            @include xlarge {
                background-image: url('../../images/Conference-2024-Home-Page-Banner-wide.png');
                background-position: left;
            }

            @include narrow {
                padding-top: 50px;
            }

            .md-up-conference-banner {
                padding-top: 2.5em;
            }
        }

        @include narrow {

            .lower-block {
                margin-bottom: 0;
                margin-top: 1rem;
            }

            .lower-right {
                margin-bottom: 1rem;
            }
        }

        @include medium-only {
            p {
                font-size: 16px;
            }
        }

        @include medium {
            .lower-block {
                margin-top: 0;
            }
            .conference-svg {
                margin-top: 0.2rem;
            }

            .conference--homepage-support-text p {
                max-width: 190px;
                padding-top: 4px;
            }
        }

        @include large {
            .conference-svg {
                margin-top: -1rem;
            }

            .conference--homepage-tagline-wrapper {
                display: inline-block;
            }

            .conference--homepage-subtitle p {
                padding-top: 11px;
                font-size: 23px
            }

            .conference--homepage-text-divider {
                display: none;
            }

            .conference--homepage-support-text p {
                display: inline-block;
                padding-left: 16px;
                font-size: 26px;
                line-height: 38px;
            }
        }

        @include xlarge {
            .lower-block {
                margin-top: 0;
            }

            .conference--homepage-tagline {
                max-width: 180px;
            }

            .conference--homepage-subtitle p {
                display: inline-block;
            }

            .conference--homepage-text-divider {
                display: inline-block;
                width: 2px;
                height: 57px;
                background: white;
                margin: 0 16px;
            }
        }

        .conference-svg {
            max-width:100%;
            margin-top: -1rem;
        }

    }

}

.icon-watermark {
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: 100;

    @include narrow {
        display: none;
    }

}

.padded-left {
    @include medium {
        padding-left: 240px;
    }
}


// -----------------------------------------------------------------------------
//
// -----------------------------------------------------------------------------


h1 {
    position: relative;
}

.fancy-header {
    position: relative;
}

.fancy-header:before {
    content: " ";
    position: absolute;
    top: -50px;
    background-color: $color-api-centre-blue;
    width: 60px;
    height: 4px;
    border-radius: 999px;

    @include narrow {
        top: -25px;
    }

}

.cancel-fancy-header {
    .fancy-header:before {
        content: none;
    }
}

.fancy-header--low:before {
    top: -10px;
    background-color: #D8D8D8;
}


.fancy-header--yellow:before {
    background-color: #FFCF32;
}

h3 + form.form-css-label {
    margin-top: 50px;
}


.country-select-flag {
    display: none;
}


.wrapper {
    padding-left: 1.5em;
    padding-right: 1.5em;

    @include medium {
        padding-left: 2.5em;
        padding-right: 2.5em;
    }

    @include large {
        padding-left: 4.5em;
        padding-right: 4.5em;
    }

}









// -----------------------------------------------------------------------------
// SVG modifiers
// -----------------------------------------------------------------------------

.icon--blue {
    fill: #254184;
    color: #254184;
}

.icon--green {
    fill: #49C2AC;
    color: #49C2AC;
}

.icon--yellow {
    fill: #FFCF32;
    color: #FFCF32;
}

.icon--light-blue {
    fill: $color-api-centre-blue;
    color: $color-api-centre-blue;
}

.icon--red {
    fill: $color-payments-red;
    color: $color-payments-red;
}

.icon--grey {
    fill: #777777;
    color: #777777;
}

.icon--dark-grey {
    fill: #555555;
    color: #555555;
}

.icon--purple {
    fill: #78438E;
    color: #78438E;
}

.icon--green-forest {
    fill: #78C675;
    color: #78C675;
}

.icon--white {
    fill: white;
    color: white;
}

.icon---off-white {
    fill: #D0D0D0;
    color: #D0D0D0;
}

.btn:hover > .icon--blue {
    fill: white;
    color: white;
}


.icon--blue-other {
    fill: #0A82C8;
    color: #0A82C8;
}


// -----------------------------------------------------------------------------
// Media blocks
// -----------------------------------------------------------------------------

.info-left {
    position: absolute;
    top: 0;
    right: 0;
    font-size: 14px;
    color: #0079CF;
    font-weight: 700;
    margin-top: 0;

    svg {
        position: relative;
        top: 2px;
    }

}

.large-letter {
    opacity: 0.2;
    font-size: 72px;
    color: #333333;
    font-weight: 900;
    line-height: .75;
    margin-right: 20px;
    width: 75px;
    display: inline-block;
    text-align: right;
}

.side-note {
    margin-top: 55px;
}

// -----------------------------------------------------------------------------
// theming
// -----------------------------------------------------------------------------



// $color-payments-blue
// $color-payments-red
// $color-payments-purple
// $color-payments-green
// $color-payments-yellow
// $color-payments-grey



// .-theme--blue {
//     .side-nav-column > h4 {
//         color: $color-payments-blue;
//     }

//     .border-left {
//         border-left-color: $color-payments-blue;
//     }

//     .h4.active,
//     .side-nav__link.active {
//         color: $color-payments-blue;
//         background-color: #F4F5F5;
//     }

//     .side-nav__second-tier a.active {
//         background-color: #F4F5F5;
//     }

//     .tile__key {
//         background-color: $color-payments-blue;
//     }

// }




@mixin theming($theme-color) {
    .side-nav-column > h4 {
        color: $theme-color;
    }

    .border-left {
        border-left-color: $theme-color;
    }

    .solid-background-notification{
        background-color: $theme-color;
        color: $color-white;
        padding-top: 1em;
        a.btn {
            background-color: $color-white;
            color: $theme-color;
        }
    }

    .h4.active,
    .side-nav__link.active {
        color: $theme-color;
        // background-color: #F4F5F5;
    }

    .side-nav__second-tier a.active {
        // background-color: #F4F5F5;
        color: $theme-color;
    }

    .tile__key {
        background-color: $theme-color;
    }

    .fancy-header:before {
        background-color: $theme-color;
    }

    .banner__image {
        background-color: $theme-color;
    }



}





.-theme--blue {
    @include theming($color-payments-blue);
    &.mobile-nav__item:before {
        background-color: $color-payments-blue;
    }
}
.-theme--red {
    @include theming($color-payments-red);
    &.mobile-nav__item:before {
        background-color: $color-payments-red;
    }
}
.-theme--green {
    @include theming($color-payments-green);
    &.mobile-nav__item:before {
        background-color: $color-payments-green;
    }
}
.-theme--purple {
    @include theming($color-payments-purple);
    &.mobile-nav__item:before {
        background-color: $color-payments-purple;
    }
}
.-theme--yellow {
    @include theming($color-payments-yellow);
    &.mobile-nav__item:before {
        background-color: $color-payments-yellow;
    }
}
.-theme--teal {
    @include theming($color-payments-teal);
    &.mobile-nav__item:before {
        background-color: $color-payments-teal;
    }
}






// -----------------------------------------------------------------------------
// theming
// -----------------------------------------------------------------------------

.show-more {
    display: inline-block;
    position: relative;
}

.show-more__trigger {
    width: 40px;
    display: inline-block;
    text-align: center;
    position: relative;
    z-index: 300;
    cursor: pointer;
    margin-right: 3px;
    padding-top: 3px;
}

.utility-menu {
    margin-top: 0!important;

    > * {
        display: inline-block;
    }

}



.show-more__menu {
    position: absolute;
    background-color: white;
    padding: 10px 20px 15px;
    border-radius: 4px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
    width: 230px;
    right: 0;
    top: 51px;
    text-align: left;
    z-index: 200;

    a {
        color: #555555;
    }

}

.show-more__menu:after {
    background: white;
    border-radius: 4px;
    box-shadow: 0px 1px 4px 0 rgba(0, 0, 0, 0.4);
    content: "";
    height: 74px;
    position: absolute;
    right: 0px;
    top: -70px;
    width: 49px;
}

.show-more__menu:before {
    background: white;
    content: "";
    position: absolute;
    right: 0px;
    top: 0em;
    width: 54px;
    height: 20px;
    z-index: 9;
}

.show-more__menu-left {
    left: -3px;
    right: initial;
    top: 16px;
    width: 260px;
}


.show-more__menu-left:before,
.show-more__menu-left:after {
    right: initial;
    left: 0;
    width: 49px;

}

.list-inline__item,
.breadcrumbs__item {
    margin-top: 0;
    position: relative;
}




// -----------------------------------------------------------------------------
// Icons
// -----------------------------------------------------------------------------


.icon-edit-folder {
    width: 23px;
    height: 17px;
}

.icon-home {
    width: 20px;
    height: 17px;
}

.icon-close--utility {
    position: absolute;
    right: -14px;
    top: 16px;
}

.icon-close--bulk-upload {
    position: absolute;
    right: -14px;
    top: 10px;
    margin-top: 0;
    @include narrow {
        top: 0;
    }
}

.icon-btn-arrow--breadcrumbs {
    width: 9px;
    height: 6px;
    position: absolute;
    left: -5px;
    top: 38%;
}

.icon-calendar {
    //width: 18px;
    //height: 22px;
    //margin-right: 10px;
    //padding-top: 8px;
    //vertical-align: middle;
    width: 12px;
    height: 28px;
    margin-right: 10px;
    /* padding-top: 8px; */
    vertical-align: top;
}

.icon-download {
    width: 20px;
    height: 18px;
    fill: #254184;
    color: #254184;
    transform: $base-transition;
}


.btn--secondary--white:hover {
    .icon-download path {
        fill: #ffffff;
        color: #ffffff;
    }
}

.icon-settings {
    width: 17px;
    height: 17px;
}

.icon-move {
    width: 26px;
    height: 17px;
}

.icon-delete {
    width: 16px;
    height: 21px;
}

.icon-doc--bulk-upload {
    position: absolute;
    top: 0;
    left: 0;
}

.icon-doc {
    width: 18px;
    height: 24px;
}

.icon-folder {
    width: 22px;
    height: 18px;
}

.icon-back-arrow {
    width: 9px;
    height: 15px;
}

.icon-add {
    width: 9px;
    height: 9px;
}

.icon-folder-upload {
    width: 20px;
    height: 19px;
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(black, 0.9);
    z-index: 9000;
    padding: 2rem;

    h2, h3, h4, p {
        color: white;
    }

}

.overlay__header {
    margin-bottom: 3em;
}

.overlay__col-1 {
    max-width: 200px;
    float: left;
    margin-right: 4em;


    h6 + * {
        margin-top: 0;
    }

    h4 + .xsmall {
        margin-top: 0;
    }

}

.overlay__col-2 {
    max-width: 700px;
    float: left;
}



// -----------------------------------------------------------------------------
// breadcrumbs
// -----------------------------------------------------------------------------

.breadcrumbs {
    display: inline-block;
}

// .breadcrumbs__item:first-child {
//     .icon-btn-arrow--breadcrumbs {
//         // display: none;
//     }
// }


.breadcrumbs__item {
    color: #777777;
    position: relative;
}

.show-more--breadcrumbs {
    .show-more__trigger {
        position: relative;
        top: 3px;
    }
}


// -----------------------------------------------------------------------------
// modals
// -----------------------------------------------------------------------------

.small-modal {
    background: #FFFFFF;
    box-shadow: 0 6px 10px 0 rgba(0,0,0,0.20);
    border-radius: 4px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 50px 70px;
    z-index: 99;

}

.small-modal--session {
    @include narrow {
        width: 90%;
        padding: 50px 30px;
    }
}

.small-modal--delete {
    width: 500px;
}

.small-modal--folder {
    width: 500px;
}

.small-modal--move {
    width: 550px;

    li {
        margin-left: 0;

        &:before {
            display: none;
        }
    }

}

.icon-close {
    position: absolute;
    top: 15px;
    right: 15px;
    width: 14px;
    height: 14px;
    cursor: pointer;
}

.icon-close-device {
    position: absolute;
    top: -12px;
    width: 14px;
    height: 14px;
    cursor: pointer;
}

.icon-btn-arrow {
    width: 6px;
    height: 9px;
}

.icon-search--utility {
    width: 20px;
    height: 20px;
    fill: #000;
    color: #000;
}

.search-utility-menu {
    display: none;
    position: absolute;
    right: 1.5em;
    z-index: 300;
    top: 25px;

    &.document-search-input--visible {
        display: inline-block;
    }
}


.btn-reset {
    border: none;
    border-width: 0;
    background-color: transparent;
    border-color: buttonface;
    border-image: initial;
    border-style: outset;
    border-width: 2px;
    padding: 2px 6px 3px;
    border: none;
    outline:none;

}





.document-search-input {
    background-color: white;
    padding: 11px 10px 6px;
    z-index: 200;
    height: 70px;
    border-bottom: 1px solid #0079CF;
}

.document-search-input {
    display: none;

    &.document-search-input--visible {
        display: inline-block;
    }
}


.download-btn {
    position: absolute;
    bottom: -8px;
    right: 0;
}


.icon-baaa {
    fill: #254184;
    color: #254184;
}




// -----------------------------------------------------------------------------
// forms
// -----------------------------------------------------------------------------

textarea:focus, textarea.form-control:focus, input.form-control:focus, input[type=text]:focus, input[type=password]:focus, input[type=email]:focus, input[type=number]:focus, [type=text].form-control:focus, [type=password].form-control:focus, [type=email].form-control:focus, [type=tel].form-control:focus, [contenteditable].form-control:focus {
    -webkit-box-shadow: inset 0 -2px 0 #2196f3;
    box-shadow: inset 0 -2px 0 #2196f3;
}

.register-input-in-table {
    box-shadow: none !important;
    &:hover {
        box-shadow: none; //inset 0 -2px 0 #CACACA;
    }
    &:focus {
        box-shadow: none; //inset 0 -2px 0 #CACACA !important;
    }
}


// Remove annoying IE input clear icon.
input::-ms-clear {
    display: none;
}

// -----------------------------------------------------------------------------
// Banner homepage
// -----------------------------------------------------------------------------


.banner--homepage {
    background-image: url('../../images/homepage-hero.png');
    background-repeat: no-repeat;
    background-position: 100% 100%;
    background-color: #ebecee;
    background-size: cover;

    @include narrow {
        height: 270px;
        background-position: 70% 100%;
    }

    @include medium {
        height: 450px;
        background-position: 60% 100%;
    }

    @include large {
        height: 500px;
    }

    @include xlarge {
        background-position: 100% 65%;
    }

    h1 {
        color: #051923;

        @include narrow {
            font-size: 24px;
            margin-top: 20px;
            line-height: 1.1;
        }

        @include medium {
            font-size: 39px;
            // margin-top: 14px;
            line-height: 1.2;
        }

        @include large {
            font-size: 56px;
            // margin-top: 14px;
            line-height: 1.2;
        }

    }

    .btn {
        font-family: "Open Sans", sans-serif;
    }

}

.z-index-300 {
    position: relative;
    z-index: 300;
}

.homepage-icon {
    margin-left: -40px;
    margin-right: 30px;

    @include medium {
        margin-left: -40px;
        margin-right: 10px;
        height: 160px;
        position: relative;
        top: -60px;
    }

    @include large {
        height: 227px;
        margin-right: 20px;
    }

}

.center-vertically {

    @include medium {
        position: relative;
        top: 50%;
        transform: perspective(1px) translateY(-50%);
    }

    // .banner--homepage & {

    // }

}




// -----------------------------------------------------------------------------
// Banner homepage
// -----------------------------------------------------------------------------

.sign-up {
    position: relative;
    margin-top: 10px;
}

.sign-up__submit {
    position: absolute;
    right: 0;
    top: 10px;
    width: 40px;
    height: 45px;
    cursor: pointer;
    transition: $base-transition;

    &:hover {
        background-color: #ccc;
    }
}


.category-head {

    > * {
        display: inline-block;
        vertical-align: middle;
    }


    + h4 {
        margin-top: 25px;
    }
}







// -----------------------------------------------------------------------------
// Grid
// -----------------------------------------------------------------------------




.row {
    @include large {
        display: flex; /* equal height of the children */
        margin-bottom: 20px;
    }
}

.col {
    @include large {
        flex: 1; /* additionally, equal width */
        align-items: stretch;
        // padding: .5em;
        // margin: 1em;
        // border: solid;

        .tile {
            align-items: stretch;
        }
    }
}

.col--by-two {
    display: flex;
    flex-direction: row;

    @include narrow {
        flex-direction: column;
    }


}


.flow {
    flex-flow: column;

    > div {
        height: 50%;
    }
}




.row2 {
  display: flex; /* equal height of the children */
}

.col2 {
  flex: 1; /* additionally, equal width */
  align-items: stretch;
  padding: 4em;
  border: solid;

    > * {
        align-items: stretch;
    }
}


.flow {
    flex-flow: column;
}

.col.left {

    @include large {
        width: 335px;
        flex: 0 335px;
    }

}







.box {
    display: flex;
    flex-flow: row nowrap;
    align-content: center;
    align-items: center;
    height: 100%;

    > div {
        margin-bottom: 20px;
        margin-top: 0;
        width: 50%;
        margin-right: 10px;

        + div {
            margin-right: 0;
            margin-left: 10px;
        }

        @include narrow {
            width: 100%;
            margin-left: 0;
            margin-right: 0;

            + div {
                margin-left: 0;
                margin-right: 0;
            }
        }

        @include large {
            width: 100%;
            margin-left: 0;
            margin-right: 0;

            + div {
                margin-left: 0;
                margin-right: 0;
            }
        }

    }

    @include narrow {
        flex-flow: column nowrap;
    }

    @include large {
        flex-flow: column nowrap;
    }

    div:last-child {
        @include large {
            margin-bottom: 0;
        }
    }


}

.box .A {
    // order: 1;
    // flex:0 1 auto;
    align-self: stretch;
    min-width: 0;
    min-height: calc(50% - 10px);
}

.box .B {
    order: 2;
    flex:0 1 auto;
    align-self: stretch;
    min-width: 0;
    min-height: 50%;
}







.row--news-style {
    display: block;


    .tile--large {
        margin-right: 0;
    }

    .tile--image-top {
        margin-right: 10px;
        @include narrow {
            margin-right: 0;
        }

        @include large {
            margin-right: 20px;
            margin-left: 0;
        }

        + .tile--image-top {
            margin-right: 0;
            margin-left: 10px;

            @include narrow {
                margin-right: 0;
                margin-left: 0;
            }

            @include large {
                margin-right: 20px;
                margin-left: 0;
            }

        }
    }

}

@include tiles-medium {
    .row--news-style {
        display: block;


        .tile--large {
            margin-right: 0;
        }

        .tile--image-top {
            margin-right: 10px;

            + .tile--image-top {
                margin-right: 0;
                margin-left: 10px;
            }
        }

        .box {
            display: -webkit-box;
            display: flex;
            -webkit-box-orient: horizontal;
            -webkit-box-direction: normal;
            flex-flow: row nowrap;
            align-content: center;
            -webkit-box-align: center;
            align-items: center;
            height: 100%;
        }

        .col.left {
            width: 100%;
        }

        .box > div {
            margin-right: 10px;
        }

        .box > div + div {
            margin-right: 0;
            margin-left: 10px;
        }

    }

}







// -----------------------------------------------------------------------------
// Flex list wrap
// -----------------------------------------------------------------------------


/*Flex items*/
.list {
    display: flex;
    flex-wrap: wrap;

    .tile {
        width: 100%;
    }

}


.list--thirds {
    margin-left: -10px;
    margin-right: -10px;
    margin-bottom: 20px;

    .list-item {

        @include large {
            width: 33.33%;
        }

        @include medium {
            width: 50%;
        }

        @include small {
            width: 100%;
        }

    }

}


.list--half {
    margin-left: -10px;
    margin-right: -10px;
    margin-bottom: 20px;

    .list-item {

        @include medium {
            width: 50%;
        }

        @include small {
            width: 100%;
        }
    }

}

.list-item {
    display: flex;
    padding: 0 10px;
    width: 100%;
    margin-top: 20px;
}


.list-content {
    background-color: #fff;
    display: flex;
    flex-direction: column;
    padding: 1em;
    width: 100%;
}

.list-content p {
    flex: 1 0 auto;
}



.align-bottom {
    align-self: flex-end;
}


.wrapper-padding {
    padding-top: 180px;
    padding-bottom: 150px;
}


.nav-lower {
    bottom: 0;
    height: 90px;
    background-color: white;
    left: 240px;
    right: 0;
    padding-top: 30px;

    a {
        font-size: 12px;
        text-transform: uppercase;

        &:hover {
            opacity: 0.7;
        }
    }

    @include narrow {
        left: 0;
    }

}

.page-nav {
    height: 80px;
    background-color: white;
    left: 0;
    right: 0;
    padding-top: 30px;

    a {
        font-size: 12px;
        text-transform: uppercase;

        &:hover {
            opacity: 0.7;
        }
    }

    @include large {
        left: 240px;
    }

}

.page-nav--top {
    top: 0;
    position: absolute;
    border-top: 1px solid #E5EEF3;
}

.page-nav--bottom {
    border-bottom: 1px solid #E5EEF3;
    margin-top: 100px;
}


.icon-btn-arrow--reverse {
    transform: rotate(180deg);
}

.toolbar {
    height: 70px;
}




.u-mb-0 {
    margin-bottom: 0;
}


.supporting-document {
    .label {
        position: relative;
    }
}

// -----------------------------------------------------------------------------
// DMS LOADER
// -----------------------------------------------------------------------------

.loading-dms {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(#000000, 0.5);
    z-index:9999;
    h2 {
        color: white;
        margin-top: 83px;
        margin-left: -37px
    }
}


.sk-circle {
  //margin: 100px auto 50px;
  width: 60px;
  height: 60px;
  //position: relative;
position: absolute;
top: 50%;
left: 50%;
margin-right: -50%;
transform: translate(-50%, -50%)

}
.sk-circle .sk-child {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}
.sk-circle .sk-child:before {
  content: '';
  display: block;
  margin: 0 auto;
  width: 15%;
  height: 15%;
  background-color: white;
  border-radius: 100%;
  -webkit-animation: sk-circleBounceDelay 1.2s infinite ease-in-out both;
          animation: sk-circleBounceDelay 1.2s infinite ease-in-out both;
}
.sk-circle .sk-circle2 {
  -webkit-transform: rotate(30deg);
      -ms-transform: rotate(30deg);
          transform: rotate(30deg); }
.sk-circle .sk-circle3 {
  -webkit-transform: rotate(60deg);
      -ms-transform: rotate(60deg);
          transform: rotate(60deg); }
.sk-circle .sk-circle4 {
  -webkit-transform: rotate(90deg);
      -ms-transform: rotate(90deg);
          transform: rotate(90deg); }
.sk-circle .sk-circle5 {
  -webkit-transform: rotate(120deg);
      -ms-transform: rotate(120deg);
          transform: rotate(120deg); }
.sk-circle .sk-circle6 {
  -webkit-transform: rotate(150deg);
      -ms-transform: rotate(150deg);
          transform: rotate(150deg); }
.sk-circle .sk-circle7 {
  -webkit-transform: rotate(180deg);
      -ms-transform: rotate(180deg);
          transform: rotate(180deg); }
.sk-circle .sk-circle8 {
  -webkit-transform: rotate(210deg);
      -ms-transform: rotate(210deg);
          transform: rotate(210deg); }
.sk-circle .sk-circle9 {
  -webkit-transform: rotate(240deg);
      -ms-transform: rotate(240deg);
          transform: rotate(240deg); }
.sk-circle .sk-circle10 {
  -webkit-transform: rotate(270deg);
      -ms-transform: rotate(270deg);
          transform: rotate(270deg); }
.sk-circle .sk-circle11 {
  -webkit-transform: rotate(300deg);
      -ms-transform: rotate(300deg);
          transform: rotate(300deg); }
.sk-circle .sk-circle12 {
  -webkit-transform: rotate(330deg);
      -ms-transform: rotate(330deg);
          transform: rotate(330deg); }
.sk-circle .sk-circle2:before {
  -webkit-animation-delay: -1.1s;
          animation-delay: -1.1s; }
.sk-circle .sk-circle3:before {
  -webkit-animation-delay: -1s;
          animation-delay: -1s; }
.sk-circle .sk-circle4:before {
  -webkit-animation-delay: -0.9s;
          animation-delay: -0.9s; }
.sk-circle .sk-circle5:before {
  -webkit-animation-delay: -0.8s;
          animation-delay: -0.8s; }
.sk-circle .sk-circle6:before {
  -webkit-animation-delay: -0.7s;
          animation-delay: -0.7s; }
.sk-circle .sk-circle7:before {
  -webkit-animation-delay: -0.6s;
          animation-delay: -0.6s; }
.sk-circle .sk-circle8:before {
  -webkit-animation-delay: -0.5s;
          animation-delay: -0.5s; }
.sk-circle .sk-circle9:before {
  -webkit-animation-delay: -0.4s;
          animation-delay: -0.4s; }
.sk-circle .sk-circle10:before {
  -webkit-animation-delay: -0.3s;
          animation-delay: -0.3s; }
.sk-circle .sk-circle11:before {
  -webkit-animation-delay: -0.2s;
          animation-delay: -0.2s; }
.sk-circle .sk-circle12:before {
  -webkit-animation-delay: -0.1s;
          animation-delay: -0.1s; }

@-webkit-keyframes sk-circleBounceDelay {
  0%, 80%, 100% {
    -webkit-transform: scale(0);
            transform: scale(0);
  } 40% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

@keyframes sk-circleBounceDelay {
  0%, 80%, 100% {
    -webkit-transform: scale(0);
            transform: scale(0);
  } 40% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

.dms-move {
    padding-top: 55px;
    padding-bottom: 55px;
}


.doc-placeholder {
    background: white;
    width: 450px;
    height: 600px;
    position: relative;
    margin-top: 25px;

    @include xlarge {
        width: 700px;
        height: 900px;
    }

}


.icon-doc--large {
    width: 54px;
    height: 84px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    @include xlarge {
        width: 84px;
        height: 104px;
    }

}

.flex {
  align-content: stretch;
  align-items : center;
  display : flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
}

.height-hundy {
    height: 100%;
}



// -----------------------------------------------------------------------------
// DMS EDIT MODAL FIX
// -----------------------------------------------------------------------------



// -----------------------------------------------------------------------------
// SEARCH FORM
// -----------------------------------------------------------------------------

.search-form__button {
    position: absolute;
    right: 0;
    top: 15px;
    cursor: pointer;
}


// -----------------------------------------------------------------------------
// SEARCH FORM
// -----------------------------------------------------------------------------

.search-result__item {
    p {
        color: #555555;
    }

    .category-head + h3 {
        margin-top: 5px;
    }

    &:hover {
        h3 {
            opacity: 0.75;
        }
    }

}


.small-modal--folder {
    width: 700px;
}



.divider {
    height: 1px;
    color: #E5EEF3;
    background: #E5EEF3;
    font-size: 0;
    border: 0;
    margin-top: 60px;

    + * {
        margin-top: 60px;
    }

    &.print {
        margin-top: 30px;
        margin-bottom: 30px;
    }

}

.richtext--xsmall * {
    font-size: 12px;
}

.rich-text + * {
    margin-top: 50px;
}
.richtext--small * {
    font-size: 15px;
}

// Hide select arrows on IE.
select::-ms-expand {
    display: none;
}

// DOcument preview iframe
.doc-preview {
    width: 100%;
    height: 100%;
}

// Prevents background scrolling when modals are open in DMS.
.overlay-open {
    overflow:hidden;
    height:100%;
}

// Override Wagtails default media embedding styles
.video-content-embed {
    iframe {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100% !important;
    }

    .responsive-object {
        position: relative;
    }
}

.video-content-embed-resize {
    iframe {
        position: absolute;
        width: 100% !important;
        height: 100% !important;
        left: 0;
        top: 0;
    }

    .responsive-object {
        position: relative;
    }
}

// -----------------------------------------------------------------------------
// STATS DASHBOARD
// -----------------------------------------------------------------------------
.stats-download {
    position: absolute;
    right:0;
    top:40px;

    a {
        text-transform: none;
    }

}

.stats-download-nav {
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    li {
        margin-top: 0;
    }
    a {
        text-transform: none;
    }

}

.grid--col-spacing {
    .chart-doughnut-outer {
        padding-left: $spacing-grid;
    }
}

.chart-doughnut-outer {
    pointer-events: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: column;
}

.chart-doughnut-inner {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: column;
    text-align: center;

    > h5 {
        font-size: larger;
        color: $color-payments-tolopea;
    }
}

.chart-container {
    box-sizing: border-box;
}

.stats-feature-value {
    font-size: 30px;
    line-height: 30px;
    font-weight: bold;
    span {
        font-size: 19px;
    }
    span.print {
        font-size: 14px;
        line-height: 22px;
    }
}

.stats-value--institute {
    color: $color-payments-light-blue;
    margin-top: 5px;
}

.stats-industry--institute-wrapper {
    width: 75%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.stats-industry--line-wrapper {
    width: 100%;
    @include large {
        width: 75%;
    }
    @include xlarge {
        width: 65%;
    }
}

.stats-industry--line-total {
    border-top: 1px solid rgba(165, 165, 165, 1);
}

.stats-value--industry {
    color: $color-payments-dark-blue;
}

.stats-supporting-value {
    font-size: 24px;
    line-height: 36px;
    min-width: 40%;
    span {
        font-size: 14px;
    }
}

.stats-feature-value, .stats-supporting-value {
    span.stats-feature-support-text {
        font-size: 12px;
    }
}

.popout-menu-stats {
    position: absolute;
    right:0;
    top: 11px;

    @include narrow {
        right: -20px;
        top: -41px;
    }

    .lb-sbi-trigger {
        width: 168px;
    }

    .lb-sbi-menu {
        &:before {
            width: 183px;
        }
        &:after {
            width: 181px;
        }
    }

    .hvcs-menu {
        width: 270px;
        &:before {
            width: 185px;
        }
        &:after {
            width: 184px;
        }
    }

    .icon-settings {
        margin-left: 0.7rem;
    }



}

.stats-bank-logo {
    max-height: 144px;
    width: auto;
    position: absolute;
    top: -34px;
    right: 0;
}



// -----------------------------------------------------------------------------
// PHOTO GALLERY
// -----------------------------------------------------------------------------
.photo-gallery-image {
    @include medium {
        max-width: initial;
        vertical-align: middle;
        max-height: 100%;
        min-height: 160px;
    }
}

.photo-gallery-item {
    height: 160px;
    overflow: hidden;
}

.overflow--auto {
    overflow-x: auto;
}

.pd-right {
    padding-right: 1.5rem;
    display: inline-block;
}

.list-inline__item {
  @include narrow {
    display: block;
    width: 100%;
  }

  @include medium {
    display: inline-block;
  }
}

// -----------------------------------------------------------------------------
// PRINT STYLES
// -----------------------------------------------------------------------------
.puppeteer-pdf {

    body {
        margin: 0;
        padding: 0;
    }

    .page-content {
        padding-top: 20px;
    }

    .page-break {
        page-break-before: always;
        position: relative;
        padding-top: 20px;

        &.page-break--less-top-margin {
            padding-top: 0 !important;
        }
    }


    h1, h2, h3, h4, h5, h6 {
        font-family: 'Open Sans', sans-serif;
    }

    h1 {
        padding-top: 6px;
        padding-bottom: 10px;
        font-size: 30px;
        max-width: 80%;
    }

    h2 {
        font-size: 20px;
        padding-bottom: 8px;
        max-width: 100%;
    }

    h2 + * {
        margin-top: 8px !important;
    }

    h2.no-top-margin {
        margin-top: 0 !important;
    }

    h2.less-bottom-padding {
        padding-bottom: 2px !important;
    }

    h2.compressed-header {
        font-size: 18px;
    }

    h3 {
        font-size: 16px;
    }

    h3 + * {
        margin-top: 8px !important;
    }

    h5 {
        font-size: 10px;
    }

    .icon-calendar {
        padding-top: 0;
        height: 20px;
        width: 15px;
    }

    .spacing-25 {
        margin-top: 10px !important;
    }

    .spacing-0 {
        margin-top: 0 !important;
    }

    .stats-print-header {
        padding-top: 20px;
        margin-bottom: 30px;

        &.stats-print-header--compressed {
            padding-top: 0 !important;
            margin-bottom: 8px !important;
            margin-top: -5px !important;

            h1 {
                font-size: 22px !important;
                padding-bottom: 6px !important;
                padding-top: 0 !important;
            }
            .stats-print-header-image {
                top: -35px !important;
            }

        }
    }

    .stats-feature-value {
        font-size: 17px;
        span {
            font-size: 14px;
        }
    }

    .stats-supporting-value {
        font-size: 12px !important;
        span {
            font-size: 9px !important;
        }
        &.stats-value--industry--supporting-text {
            line-height: 28px !important;
        }
    }

    .grid--row-spacing, .grid--row-spacing > * {
        margin-bottom: 6px !important;
    }

    .stats-header-month {
        font-size: 15px;
        span {
            display: inline-block;
            line-height: 20px;
            vertical-align: top;
        }
    }

    .stats-print-header-image {
        position: absolute;
        right: 0px;
        top: -10px;
    }

    .responsive-table {
        overflow-x: hidden;
        width: 100% !important;
        max-width: 100% !important;
    }

    .table {
        margin-top: 3px;
        max-width: 100% !important;
    }

    .table th {
        font-size: 8px;
        padding: 5px;
    }

    .table td {
        font-size: 9px;
        padding: 5px 7px 5px;
    }

    .narrow-full {
        width: 50% !important;
    }

    .stats-feature-support-text {
        font-size: 8px !important;
    }

    p {
        font-size: 14px;
    }

    .xsmall {
        font-size: 10px;
    }

    h3.xsmall {
        font-size: 12px;
    }

    .key-label {
        font-size: 10px;
    }

    .color-key {
        height: 10px;
        width: 10px;
    }

    .color-key-item {
      margin-left: 1rem;
      cursor: pointer;

      .key-label {
        color: $color-text-grey;
        font-weight: normal;
        border-bottom: none;
        padding-bottom: 0;
      }
    }

    .tile--feature-value {
        .feature-value--value {
            font-size: 80px !important;
        }

        .feature-value--symbol, .feature-value--scale {
            font-size: 50px !important;
        }

        .feature-value--symbol {
            line-height: 100px !important;
        }

        padding: 20px !important;
    }

    .chart-container canvas {
        width: 100% !important;
        height: auto !important;
        display: block !important;
    }

    .key-metric-title {
        font-size: 15px;
    }

    .fraud-report {
        h4 {
            font-size: 12px;
            font-weight: 600;
        }

        ul li, ol li {
            padding: 0;
            margin: 0;
        }

        .chart-doughnut-inner {
            > h5 {
                font-size: 14px;
            }
            > span {
                font-size: 10px;
            }
        }
    }
}

.fraud-report {
    .chart-legend {
        h4 {
            text-transform: lowercase;
            margin-top: 10px !important;
        }
        h4::first-letter {
            text-transform: uppercase;
        }
    }
    .key-label {
        text-transform: uppercase;
    }
}

.flex-container {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    flex: 1;
}

.u-flex {
    display: flex;
}

.u-flex-1 {
    flex: 1;
}

.u-flex-grow {
    flex-grow: 1;
}

.u-flex-wrap {
    flex-wrap: wrap;
}

//------
// Call to action block
//------
.call-to-action-block {
    position: relative;
    display: block;
    float: left;
    padding: 2em;
    width: 100%;
    background-color: #e4eef3;

    span {
        display: inline-block;
        vertical-align: middle;
        line-height: 39px;
        font-weight: bold;
        float: left;
    }

    .call-to-action-button {
        margin-top: 10px !important;
        float: left;
        display: block;
        clear: both;

        @include medium {
            float: right;
            margin-top: 0px !important;
            display: inline-block;
            clear: none;
        }
    }
}


.logo-api-mobile  {
    display: none;
}

.footnote__statement {
    display: block;
    margin-top: 7px;
    margin-bottom: 7px;

    @include xlarge {
        display: inline;
        margin-top: 0;
        margin-bottom: 0;
    }

}


.footnote {
    * + .xsmall {
        margin-top: 0;
    }
}

.footnote__social {
    @include large {
        text-align: right;
    }
}


.debug * {
    background: rgba(#F9CA24, 0.05);
    box-shadow: 0 0 0 1px #F9CA24;

    &::after,
    &::before {
        background: rgba(#FF7979, 0.05);
        box-shadow: 0 0 0 1px #FF7979;
    }
}
