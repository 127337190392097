

// Minimal style to prevent conflicting with other links
// and make it easy to override
a {
    color: $color-link-blue;
    transition: $base-transition;
    text-decoration: none;

    &:hover {
        color: darken($color-link-blue, 5%)
    }

}




// Basic link, has visited states
.link--basic {
    &:visited {
        color: $color-black-50;

        &:hover {
            color: darken($color-black-50, 50%)
        }
    }
}


// Border styles for links
.link--border {
    border-bottom: 1px solid;
    text-decoration: none;
    border-bottom-color: inherit;
}
