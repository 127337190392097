html {
    -webkit-tap-highlight-color: transparent;
    color: $color-text-grey;
    font-family: $font-family-primary-sans-serif;
    font-size: $base-font-size;
    height: 100%;
    line-height: 1.6;
    min-height: 100%;

    @include narrow {
        font-size: 16px;
    }
}

body {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-smooth: always;
    height: 100%;
    position: relative;
}

.container {
    margin: 0 auto;
    width: 100%;
    max-width: 64rem;
    padding: 0 $spacing;
}



