@keyframes loading {
    0% { opacity: 1; }
    50% { opacity: .5; }
    100% { opacity: 1; }
}

@keyframes fade-in--20 {
    0% { opacity: .2; }
    100% { opacity: 1; }
}

@keyframes fade-out--20 {
    0% { opacity: 1; }
    100% { opacity: .2; }
}

@keyframes fade-in--60 {
    0% { opacity: .6; }
    100% { opacity: 1; }
}

@keyframes fade-out--60 {
    0% { opacity: 1; }
    100% { opacity: .6; }
}

@keyframes slideOut {
    0% { transform: translateY(0) }
    100% { transform: translateX(-100%) }
}

@keyframes slideIn {
    0% { transform: translateX(-100%) }
    100% { transform: translateY(0) }
}

@keyframes slideUp {
    0% { transform: translateY(0) }
    100% { transform: translateY(-100%) }
}

@keyframes slideDown {
    0% { transform: translateY(-100%) }
    100% { transform: translateY(0) }
}

@keyframes hvr-wobble-vertical {
  16.65% { transform: translateY(8px); }
  33.3% { transform: translateY(-6px); }
  49.95% { transform: translateY(4px); }
  66.6% { transform: translateY(-2px); }
  83.25% { transform: translateY(1px); }
  100% { transform: translateY(0); }
}


@keyframes bounceInLeft {
  from, 50%, 65%, 75%, 90%, to {
    animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
  }
  0%  { transform: translate3d(-100%, 0, 0); }
  50% { transform: translate3d(0px, 0, 0); }
  65% { transform: translate3d(-45px, 0, 0); }
  75% { transform: translate3d(-25px, 0, 0); }
  90% { transform: translate3d(-5px, 0, 0); }
  to  { transform: none; }
}
