.month-picker-tab {

  @include narrow {
    float: left;
  }

}
.month-picker {
  position: relative;
}

.month-picker > .month-picker-container {
  position: relative;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  z-index: 100;
  top: 1px;
  left: -10000px;
  -webkit-transition: left 0ms cubic-bezier(0.23, 1, 0.32, 1) 450ms;
  -moz-transition: left 0ms cubic-bezier(0.23, 1, 0.32, 1) 450ms;
  transition: left 0ms cubic-bezier(0.23, 1, 0.32, 1) 450ms;
}

@media screen and (max-width: 767px) {
  .month-picker > .month-picker-container {
    position: fixed;
    top: 0;
    left: -10000px;
    width: 100%;
    height: 100%;
    -webkit-transition: left 0ms cubic-bezier(0.23, 1, 0.32, 1) 450ms;
    -moz-transition: left 0ms cubic-bezier(0.23, 1, 0.32, 1) 450ms;
    transition: left 0ms cubic-bezier(0.23, 1, 0.32, 1) 450ms;
  }
}

.month-picker > .month-picker-container.month-picker-table {
  display: block;
}

.month-picker > .month-picker-container.show {
  left: 0;
  -webkit-transition: left 0ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  -moz-transition: left 0ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  transition: left 0ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
}

.month-picker > .month-picker-container.show .month-picker-overlay {
  left: 0;
}

.month-picker > .month-picker-container .month-picker-overlay {
  position: fixed;
  height: 100%;
  width: 100%;
  z-index: 9;
  top: 0;
  left: -10000px;
  opacity: 1;
  -webkit-tap-highlight-color: transparent;
  will-change: opacity;
  -webkit-transform: translateZ(0);
  -moz-transform: translateZ(0);
  -ms-transform: translateZ(0);
  -o-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-transition: left 0ms cubic-bezier(0.23, 1, 0.32, 1) 0ms, opacity 400ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  -moz-transition: left 0ms cubic-bezier(0.23, 1, 0.32, 1) 0ms, opacity 400ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  transition: left 0ms cubic-bezier(0.23, 1, 0.32, 1) 0ms, opacity 400ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
}

@media screen and (max-width: 767px) {
  .month-picker > .month-picker-container .month-picker-overlay {
    background-color: rgba(0, 0, 0, 0.25);
  }
}

.month-picker > .month-picker-container .cell {
  display: table-cell;
  vertical-align: middle;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
}

@media screen and (max-width: 767px) {
  .month-picker > .month-picker-container .cell {
    vertical-align: bottom;
  }
}

.month-picker > .month-picker-container .popup {
  position: absolute;
  -webkit-tap-highlight-color: transparent;
  -webkit-transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1);
  -moz-transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1);
  transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1);
  margin: 0 auto;
  z-index: 10;
  font-size: 1.2rem;
  opacity: 0;
  border-radius: 3px;
  padding: 0.4rem;
  box-sizing: content-box;

  @include narrow {
    top: 39%;
  }

}

@media screen and (max-width: 767px) {
  .month-picker > .month-picker-container .popup {
    box-sizing: border-box;
    bottom: 0;
    width: 100%;
    max-width: 100%;
    height: 14.4rem;
    -webkit-transform: translate3d(0, 14.4rem, 0);
    -moz-transform: translate3d(0, 14.4rem, 0);
    -ms-transform: translate3d(0, 14.4rem, 0);
    -o-transform: translate3d(0, 14.4rem, 0);
    transform: translate3d(0, 14.4rem, 0);
  }
  .month-picker > .month-picker-container .popup.range {
    height: 28rem;
    -webkit-transform: translate3d(0, 28rem, 0);
    -moz-transform: translate3d(0, 28rem, 0);
    -ms-transform: translate3d(0, 28rem, 0);
    -o-transform: translate3d(0, 28rem, 0);
    transform: translate3d(0, 28rem, 0);
  }
  .month-picker > .month-picker-container .popup.range .pad {
    margin-top: 0.4rem;
  }
  .month-picker > .month-picker-container .popup.range .pad:first-of-type {
    margin-top: 0;
  }
}

@media screen and (min-width: 768px) {
  .month-picker > .month-picker-container .popup {
    -webkit-transform: translate3d(0, -64px, 0);
    -moz-transform: translate3d(0, -64px, 0);
    -ms-transform: translate3d(0, -64px, 0);
    -o-transform: translate3d(0, -64px, 0);
    transform: translate3d(0, -64px, 0);
    top: 0;
    width: 20rem;
  }
  .month-picker > .month-picker-container .popup.range {
    width: 40.6rem;
    padding: 0.6rem;
  }
}

.month-picker > .month-picker-container .popup.show {
  opacity: 1;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.month-picker > .month-picker-container .popup:after {
  content: ' ';
  clear: both;
  display: table;
}

.month-picker > .month-picker-container .popup .pad {
  position: relative;
}

@media screen and (min-width: 768px) {
  .month-picker > .month-picker-container .popup .pad {
    box-sizing: border-box;
    float: left;
    width: 20rem;
  }
  .month-picker > .month-picker-container .popup .pad:nth-of-type(2) {
    float: right;
  }
}

.month-picker > .month-picker-container .popup .pad > div label {
  display: block;
  font-size: 1.4rem;
  text-align: center;
  line-height: 3.4rem;
}

.month-picker > .month-picker-container .popup .pad > div label b {
  font-weight: normal;
  margin-right: 0.5em;
}

.month-picker > .month-picker-container .popup .pad > div i {
  font-style: normal;
  text-align: center;
  width: 3.4rem;
  height: 3.4rem;
  line-height: 3.4rem;
  position: absolute;
  top: 0;
}

.month-picker > .month-picker-container .popup .pad > div i.month-picker-prev {
  left: 0;
}

.month-picker > .month-picker-container .popup .pad > div i.month-picker-next {
  right: 0;
}

.month-picker > .month-picker-container .popup .pad ul, .month-picker > .month-picker-container .popup .pad li {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.month-picker > .month-picker-container .popup .pad ul {
  display: block;
  width: 100%;
}

.month-picker > .month-picker-container .popup .pad ul:after {
  content: ' ';
  clear: both;
  display: table;
}

.month-picker > .month-picker-container .popup .pad li {
  display: block;
  float: left;
  text-align: center;
  line-height: 3.4rem;
  font-size: 1.15rem;
  border-radius: 2px;
  text-overflow: ellipsis;
  white-space: nowrap;
  *white-space: nowrap;
  overflow: hidden;
  -webkit-font-smoothing: antialiased;
  -webkit-text-stroke-width: 0.2px;
  -moz-osx-font-smoothing: grayscale;
  -webkit-transition: background-color 200ms ease-in-out, color 200ms ease-in-out;
  -moz-transition: background-color 200ms ease-in-out, color 200ms ease-in-out;
  transition: background-color 200ms ease-in-out, color 200ms ease-in-out;
}

@media screen and (max-width: 767px) {
  .month-picker > .month-picker-container .popup .pad li {
    width: 25%;
  }
}

@media screen and (min-width: 768px) {
  .month-picker > .month-picker-container .popup .pad li {
    width: 33.3333333333%;
  }
}

.month-picker > .month-picker-container .popup.light {
  color: #666;
  background-color: rgba(255, 255, 255, 0.96);
}

@media screen and (max-width: 767px) {
  .month-picker > .month-picker-container .popup.light {
    border-top: 1px solid #ccc;
    box-shadow: 0 -1px 5px rgba(0, 0, 0, 0.08);
  }
  .month-picker > .month-picker-container .popup.light.range .pad {
    border-top: 1px solid rgba(204, 204, 204, 0.5);
  }
  .month-picker > .month-picker-container .popup.light.range .pad:first-of-type {
    border-top: 0;
  }
}

@media screen and (min-width: 768px) {
  .month-picker > .month-picker-container .popup.light {
    border: 1px solid #ccc;
    box-shadow: 0 1px 5px #ddd;
  }
  .month-picker > .month-picker-container .popup.light.range .pad {
    background-color: rgba(238, 238, 238, 0.9);
  }
}

.month-picker > .month-picker-container .popup.light .pad .month-picker-btn {
  cursor: pointer;
}

@media screen and (min-width: 768px) {
  .month-picker > .month-picker-container .popup.light .pad .month-picker-btn:hover {
    background-color: $color-black-10;
  }
}

.month-picker > .month-picker-container .popup.light .pad li.active, .month-picker > .month-picker-container .popup.light .pad li.active:hover {
  background-color: $color-payments-dark-blue;
  color: white;
  cursor: default;
}

.month-picker > .month-picker-container .popup.light .pad .disable, .month-picker > .month-picker-container .popup.light .pad .disable:hover {
  background-color: transparent;
  color: #bbb;
  cursor: default;
}

.month-picker > .month-picker-container .popup.dark {
  color: #fff;
  background-color: rgba(50, 50, 50, 0.96);
}


.month-picker .month-picker-btn h6 {
  color: $color-payments-dark-blue;
  pointer-events: none;
}

.month-picker .month-picker-btn.active h6 {
  color: #fff;
  pointer-events: none;
}

.month-picker .month-picker-btn.disable h6 {
  color: #bbb;
}

@media screen and (max-width: 767px) {
  .month-picker > .month-picker-container .popup.dark.range .pad {
    border-top: 1px solid rgba(113, 113, 113, 0.41);
  }
  .month-picker > .month-picker-container .popup.dark.range .pad:first-of-type {
    border-top: 0;
  }
}

@media screen and (min-width: 768px) {
  .month-picker > .month-picker-container .popup.dark.range .pad {
    background-color: rgba(70, 70, 70, 0.9);
  }
}

.month-picker > .month-picker-container .popup.dark .pad .month-picker-btn {
  cursor: pointer;
}

@media screen and (min-width: 768px) {
  .month-picker > .month-picker-container .popup.dark .pad .month-picker-btn:hover {
    background-color: $color-black-10;
  }
}

.month-picker > .month-picker-container .popup.dark .pad li.active, .month-picker > .month-picker-container .popup.dark .pad li.active:hover {
  background-color: rgba(189, 211, 242, 0.7);
  color: #303030;
  cursor: default;
}

.month-picker > .month-picker-container .popup.dark .pad .disable, .month-picker > .month-picker-container .popup.dark .pad .disable:hover {
  background-color: transparent;
  color: #717171;
  cursor: default;
}
