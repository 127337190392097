body,
h1, h2, h3, h4, h5, h6,
blockquote, pre,
dl, dd, ol, ul,
form, fieldset, legend,
figure,
table, th, td, caption,
hr {
    margin:  0;
    padding: 0;
}
