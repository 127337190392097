.list-inline {
    margin-left: 0;
    padding: 0;
    list-style: none;
}

    .list-inline__item {
        display: inline-block;

        & + & {
            margin-left: $spacing-half;
        }

        & + & {
            @include narrow {
                margin-left: 0;
            }
        }
    }
