
ul, ol {
    padding: 0 0 0 10px;

    li {
        margin-top: 10px;
        padding: 0 0 0 10px;

    }
}

ul li ul {
    list-style-type: none;
    padding-left: 0;
    margin-left: 10px;

    li {
        position: relative;
    }

    li:before {
        content: "–"; /* Insert content that looks like bullets */
        padding-right: 8px;
        position: absolute;
        left: -8px;
        font-weight: bold;
    }

    li.no-before:before {
        display: none;

    }

}

.rich-text ul {
    li:before {
        display: none;
    }
}


li > {
    ul,
    ol {
        margin-bottom: 0;
    }
}


// Specific styles for ol
// ----------------------------------------------------------------------------

ol {
    counter-reset:li;

    > li {
        position: relative;
        list-style: none;

        &:before {
            content: counter(li) ".";
            counter-increment: li;
            position:absolute;
            top: 0;
            left: -20px;
            // color: $color-text-red;
            font-weight: 600;
        }

    }

    &.red-list {
        > li {
            &:before {
                color: $color-text-red;
            }
        }

    }


    ol {
        margin-bottom: 0;

        > li {

            &:before {
                content: counter(li, lower-alpha) ".";
            }

            li:before {
                content: counter(li, lower-roman) ".";
            }
        }
    }

}

.rich-text ol {
    li:before {
        display: none;
    }
    > li {
        list-style: inherit;
    }
}

.u-list-style-none {
    list-style-type: none;
}
