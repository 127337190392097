.color-key-container {
  position: absolute;
  top: 11px;
  right: 0;
  @include narrow {
    position: relative;
    display: block;
  }

  &.align-top {
    top: 0px;
  }
}

.color-key-item {
  margin-left: 1rem;
  cursor: pointer;

  .key-label {
    color: $color-payments-blue;
    font-weight: 700;
    border-bottom: 1px solid $color-payments-blue;
    padding-bottom: 2px;
  }

  .key-sub-label {
    color: $color-payments-blue;
    font-weight: 500;
    font-size: 14px;
    display: block;
    margin-left: 22px;
  }
}

.pdf-print {
  .color-key-item {
    margin-left: 0.3rem;
  }
}

.legend-disabled .color-key-item {
  .key-label {
    color: $color-text-grey;
    font-weight: normal;
    border-bottom: none;
    padding-bottom: 0;
  }

  .key-sub-label {
    color: $color-text-grey;
    font-weight: normal;
    border-bottom: none;
    padding-bottom: 0;
  }
}

.color-key {
  height:15px;
  width:15px;
  -webkit-border-radius:7.5px;
  -moz-border-radius:7.5px;
  -ms-border-radius: 7.5px;
  border-radius: 7.5px;
  margin-right: 0.5rem;
  display: inline-block;
  line-height: 15px;
}

.pdf-print {
  .color-key {
    margin-right: 0.1rem;
  }
}

.color-key--blue {
  background-color: $color-payments-light-blue;
}

.color-key--dark-blue {
  background-color: $color-payments-dark-blue;
}

.color-key--light-blue {
  background-color: $color-payments-lighter-blue;
}

.color-key--dark-yellow {
  background-color: $color-payments-dark-yellow;
}

.color-key--yellow {
  background-color: $color-payments-yellow;
}

.color-key--light-yellow {
  background-color: $color-payments-light-yellow;
}

.color-key--dark-orange {
  background-color: $color-payments-dark-orange;
}

.color-key--orange {
  background-color: $color-payments-orange;
}

.color-key--light-orange {
  background-color: $color-payments-light-orange;
}

.color-key--dark-teal {
  background-color: $color-payments-dark-teal;
}

.color-key--teal {
  background-color: $color-payments-teal;
}

.color-key--light-teal {
  background-color: $color-payments-light-teal;
}

.color-key--dark-red {
  background-color: $color-payments-dark-red;
}

.color-key--red {
  background-color: $color-payments-red;
}

.color-key--light-red {
  background-color: $color-payments-light-red;
}

.color-key--dark-green {
  background-color: $color-payments-dark-green;
}

.color-key--green {
  background-color: $color-payments-green;
}

.color-key--light-green {
  background-color: $color-payments-light-green;
}

.color-key--api-centre-green {
  background-color: $color-api-centre-green !important;
}

.color-key--dark-purple {
  background-color: $color-payments-dark-purple;
}

.color-key--purple {
  background-color: $color-payments-purple;
}

.color-key--light-purple {
  background-color: $color-payments-light-purple;
}

.color-key--api-centre-grey {
  background-color: $color-api-centre-grey !important;
}

.color-key--tolopea {
  background-color: $color-payments-tolopea;
}

.color-key--butterfly-bush {
  background-color: $color-payments-butterfly-bush;
}

.color-key--cold-purple {
  background-color: $color-payments-cold-purple;
}

.color-key--east-side {
  background-color: $color-payments-east-side;
}

.color-key--lilac {
  background-color: $color-payments-lilac;
}

.color-key--shocking {
  background-color: $color-payments-shocking;
}

.color-key--twilight {
  background-color: $color-payments-twilight;
}

.color-key--tea-rose {
  background-color: $color-payments-tea-rose;
}

.color-key--sherwood-green {
  background-color: $color-payments-sherwood-green;
}

.color-key--viridian {
  background-color: $color-payments-viridian;
}
.color-key--silver-tree {
  background-color: $color-payments-silver-tree;
}
.color-key--granny-apple {
  background-color: $color-payments-granny-apple;
}

.color-key--prussian-blue {
  background-color: $color-payments-prussian-blue;
}
.color-key--dark-cerulean {
  background-color: $color-payments-dark-cerulean;
}
.color-key--lochmara {
  background-color: $color-payments-lochmara;
}
.color-key--boston-blue {
  background-color: $color-payments-boston-blue;
}
.color-key--cornflower {
  background-color: $color-payments-cornflower;
}
.color-key--pattens-blue {
  background-color: $color-payments-pattens-blue;
}
.color-key--bright-turquoise {
  background-color: $color-payments-bright-turquoise;
}
.color-key--bunting {
  background-color: $color-payments-bunting;
}
.color-key--royal-blue {
  background-color: $color-payments-royal-blue;
}
.color-key--shamrock {
  background-color: $color-payments-shamrock;
}
.color-key--blue-violet {
  background-color: $color-payments-blue-violet;
}
.color-key--brink-pink {
  background-color: $color-payments-brink-pink;
}
    