// Prevent all user interaction with the element
.u-disabled {
    pointer-events: none !important;
    cursor: default !important;
}

// An accessible label that takes up no visual space
.u-accessible {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0); // Deprecated but still needed to support Firefox & IE
    border: 0;
    clip-path: inset(1px); // New (experimental) CSS property
}

.u-transition {
    transition: $base-transition !important;
}

.u-list-reset {
    padding: 0;
    margin: 0;
    list-style: none;
}


.u-pos-relative {
    position: relative;
}

.xm-pos-relative {
    @include xmedium {
        position: relative;
    }
}



.u-pos-absolute {
    position: absolute;
}

.u-inline-block {
    display: inline-block;
}

.u-inline-flex {
    display: inline-flex;
}

.lg-inline-flex {
    @include large {
        display: inline-flex;
    }
}

.u-block {
    display: block;
}

.xmd-block {
    @include xmedium {
        display: block;
    }
}

.utility-menu {

    svg {
        transition: $base-transition;

        &:hover {
            opacity: 0.7;
        }

    }

}

.u-font-weight-normal {
    font-weight: normal !important;
}

.u-overflow-unset {
    overflow: unset !important;
}

.overflow-x-scroll {
    overflow-x: scroll;
}


.stretched-link {
  &::after {
    content: "";
    @include abs(0,0,0,0,1);
    pointer-events: auto;
    background-color: transparent;
  }
}
