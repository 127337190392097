.mobile-menu {
    @include narrow {
        @include fixed(60px,0,0,0,999)
    }

    @include medium {
        position: fixed;
        top: 100px;
        left: 0;
        bottom: 0;
        width: 400px;
        z-index: 999;
        border-right: 1px solid #E5EEF3;
    }
    transform: translateX(-100%);
    transition: all 0.5s ease;
    animation-duration: .3s;
    animation-fill-mode: both;
    will-change: transform,opacity;

    height: 100%;
    background-color: #f9fbfc;
    overflow: scroll;
    width: 100%;


}

.mobile-menu__social-nav {
    padding-left: 1.5rem;
    margin-top: 25px;

    a {
        display: inline-block;
        margin-right: 20px;
    }

}

body.mobile-menu--visible {
    overflow: hidden;
    .mobile-menu {
        animation-name: slideIn;
    }
}

body.mobile-menu--hidden {
    .mobile-menu {
        animation-name: slideOut;
    }
}


.mobile-nav__item {
    text-transform: uppercase;
    color: #333333;
    font-weight: 700;
    font-size: 16px;
    border-bottom: 1px solid #E5EEF3;
    padding: 1.5rem;
    display: block;
    position: relative;

    &:before {
        // background-color: red;
        bottom: 0;
        content: " ";
        left: 0;
        position: absolute;
        top: 0;
        width: 4px;
    }

    .icon-btn-arrow {
        color: #333333;
        fill: #333333;
        // float: right;
        // margin-top: 5px;
    }



    &:hover {
        color: #0079CF;
        .icon-btn-arrow {
            fill: #0079CF;
            color: #0079CF;
        }
    }

}


.mobile-menu__contact-nav__contact {
    font-weight: 700;
}

.mobile-menu__contact-nav__phone {
    font-weight: 900;
    color: #777777;
    margin-top: 15px;
}

.mobile-menu__contact-nav__email {
    color: #777777;
    margin-top: 5px;
}


.-blue:before { background-color: #00A3DA; }
.-red:before { background-color: #FE7C67; }
.-green:before { background-color: #53C3AF; }
.-purple:before { background-color: #9261A7; }



.mobile-menu__contact-nav {
    font-size: 14px;

    > a {
        display: block;
        padding-left: 1.5rem;
        padding-right: 1.5rem;
    }
}




.nav-header-mobile {
    #burger,
    .nav__link--search {
        @include medium {
            display: none;
        }
    }
}




#burger {
    width: 20px;
    height: 14px;
    position: relative;
    transform: rotate(0deg);
    transition: .5s ease-in-out;
    cursor: pointer;

    @include medium {
        margin-left: auto;
        margin-right: auto;
    }

}

#burger span {
    display: block;
    position: absolute;
    height: 2px;
    width: 100%;
    background: white;
    opacity: 1;
    left: 0;
    transform: rotate(0deg);
    transition: .25s ease-in-out;
}


#burger span:nth-child(1) {
  top: 0px;
}

#burger span:nth-child(2),#burger span:nth-child(3) {
  top: 6px;
}

#burger span:nth-child(4) {
  top: 12px;
}

.mobile-menu--visible #burger span:nth-child(1) {
  top: 18px;
  width: 0%;
  left: 50%;
}

.mobile-menu--visible #burger span:nth-child(2) {
  transform: rotate(45deg);
}

.mobile-menu--visible #burger span:nth-child(3) {
  transform: rotate(-45deg);
}

.mobile-menu--visible #burger span:nth-child(4) {
  top: 18px;
  width: 0%;
  left: 50%;
}


.button-back {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 70px;
    border-right: 1px solid #E5EEF3;
    // background: red;

    .icon-btn-arrow {
        transform: rotate(180deg) translate(-180%, -220%);
    }

}

.button-back + .mobile-nav__text {
    padding-left: 70px;
    display: inline-block;
}



.button-forward {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    width: 70px;
    border-left: 1px solid #E5EEF3;
    // background: red;
}

.button-back svg,
.button-forward svg {
    transform: translate(180%, 220%);
    position: absolute;
}

.viewport {
    height: 100%;
    position: relative;
}

.large-panel {
    width: 300%;
    position: absolute;
    height: 100%;
    transition: $base-transition;

    .large-panel--2-active & {
        transform: translate3d(-33.33%,0,0);
    }

    .large-panel--3-active & {
        transform: translate3d(-66.66%,0,0);
    }

}


.mobile-wrapper-test {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    overflow: scroll;
    height: calc(100% - 60px);
    padding-bottom: 40px;
}

.panel {
    width: 33.33%;
    position: absolute;
    top: 0;
    bottom: 0;
    height: 100%;
}


.panel-1 {
    left: 0;
}

.panel-2 {
    left: 33.33%;
}

.panel-3 {
    left: 66.66%;
}





