



blockquote {
    font-size: 1.225em;  /* 24.5px | 22px */
    width: 50%;
    background-color: white;
    display: block;
    margin: 2em auto;
    padding: 2em;

    @include small {
        width: 100%;
        padding: 10%;
        box-sizing: border-box;
    }

}
