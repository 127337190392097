.notification {
    margin-top: 25px;
    position: relative;
    border-radius: 4px;
    padding-top: 24px;
    padding-bottom: 24px;
    padding-left: 80px;
    padding-right: 24px;
}

.notification--good {
    background: #D6F7EF;
}

.notification--bad {
    background: rgba(#F37B70, 0.3);
}

.notification--alert {
    /* resources: */
    background: #CCECF7;
}

.icon-close-notification {
    position: absolute;
    top: 20px;
    right: 20px;
}

.icon-notification {
    position: absolute;
    left: 24px;
    top: 24px;
}

.icon-locked-notification {
    background-color: #EB4E3F;
    color: #FFF;
    border-radius: 50%;
    width: 35px;
    height: 35px;
}
