

$spacing: 20px;



.side-nav-column {
    display: none;


    @include large {
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        width: 240px;
        background-color: $color-white;
        border-right: 1px solid #E5EEF3;

        &.side-nav-column--preview-mode {
            overflow: scroll;
        }

    }

    > .h4 {
        padding-left: $spacing;
        padding-right: $spacing;
        padding-top: 16px;
        padding-bottom: 16px;
        color: #333333;
        display: block;
    }

}

.side-nav {

    li {
        margin-top: 0;
    }

}

.side-nav__link {
    font-size: 12px;
    color: #555555;
    text-transform: uppercase;
    font-weight: 600;
    letter-spacing: 0.02em;

    padding-left: $spacing;
    padding-right: 40px;
    padding-top: 20px;
    padding-bottom: 20px;
    display: block;
    border-top: 1px solid #E5EEF3;
    transition: $base-transition;
    position: relative;

    // svg {
    //     float: right;
    //     margin-top: 6px;
    // }


}

.side-nav__second-tier {
    padding-bottom: 25px;

    a {
        padding-left: 35px;
        padding-right: 15px;
        padding-top: 5px;
        padding-bottom: 5px;
        font-size: 13px;
        color: #777777;
        display: block;

        &:hover {
            color: #333;
        }

    }

    li:before {
        content: "";
    }

}

.side-nav__new {
    &:after {
        background-image: url(/static/images/new-tag.png);
        background-size: 40px 38px;
        content:"";
        position: absolute;
        top: 0;
        left: 0;
        width:40px;
        height:38px;
    }
}

.side-nav__drop-down {
    display: inline-block;
    width: 40px;
    // background: #f7f7f7;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 99;


    &:before {
        border-left: 1px solid #E5EEF3;
        content: " ";
        display: block;
        height: 60%;
        left: 0;
        position: absolute;
        top: 11px;
        width: 2px;
    }

    > .arrow-container {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-90%);
    }

    &.open {
        .arrow-container {
            transform: translate(-50%,10%) rotate(180deg);
        }
    }


}




