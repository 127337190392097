// ------------------------------------
//    #MIXINS
// ------------------------------------

// Global mixins available to the entire project. Define file-specific
// mixins inside the file to which they relate.


// Mixin to drop micro clearfix into a selector.
//
// .simple-usage {
//   @include clearfix;
// }
//
// .detailed-usage {
//   @include clearfix(important);
// }

@mixin clearfix {
    &:after {
        display: table;
        content: '';
        clear: both;
    }
}

@mixin hover {
    .no-touch &:hover {
        @content;
    }
}

// style placeholder text
@mixin placeholder {
  ::-webkit-input-placeholder {@content}
  :-moz-placeholder           {@content}
  ::-moz-placeholder          {@content}
  :-ms-input-placeholder      {@content}
}


// enable/disable font smoothing
@mixin font-smoothing($toggle: true) {
    @if ($toggle == true) {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    } @else {
        -webkit-font-smoothing: subpixel-antialiased;
        -moz-osx-font-smoothing: auto;
    }
}

@mixin font-smoothing-off {
    @include font-smoothing(false);
}

// add arrow to block element
@mixin arrow($orientation: 'up', $color: #000, $size: 1em) {

    position: relative;

    &:after {
        position: absolute;
        content: '';
        border-color: $color;

        width: 0;
        height: 0;
        z-index: 7;

        @if ($orientation == 'up') {

            top: auto;
            bottom: 100%;
            left: 1.5 * $size;

            border-top: 0;
            border-left: $size solid transparent;
            border-right: $size solid transparent;

            border-bottom-width: $size;
            border-bottom-style: solid;

        } @else if ($orientation == 'down') {

            border-bottom: 0;
            border-left: $size solid transparent;
            border-right: $size solid transparent;

            border-top-width: $size;
            border-top-style: solid;

        } @else if ($orientation == 'right') {

            left: 100%;
            top: 1.5 * $size;

            border-right: 0;
            border-top: $size solid transparent;
            border-bottom: $size solid transparent;

            border-left-width: $size;
            border-left-style: solid;

        } @else if ($orientation == 'left') {

            right: 100%;
            top: 1.5 * $size;

            border-left: 0;
            border-top: $size solid transparent;
            border-bottom: $size solid transparent;

            border-right-width: $size;
            border-right-style: solid;

        }

    }

}

@mixin abs($t:auto, $r:auto, $b: auto, $l: auto, $z:auto) {
    position: absolute;
    top: $t;
    right: $r;
    bottom: $b;
    left: $l;
    z-index: $z;
}

@mixin fixed($t:auto, $r:auto, $b: auto, $l: auto, $z:auto) {
    position: fixed;
    top: $t;
    right: $r;
    bottom: $b;
    left: $l;
    z-index: $z;
}
