
// Brand colors
$color-payments-blue:          #00A3DA;
$color-payments-red:           #EB4E3F;
$color-payments-purple:        #78438E;
$color-payments-green:         #49C2AC;
$color-payments-yellow:        #FFCF32;
$color-payments-grey:          #777777;
$color-payments-light-grey:    #E5EEF3;
$color-payments-dark-grey:     #E8EAED;
$color-payments-light-blue-hex: #E5F5FB;
$color-payments-light-blue-cta: #EEF9FE;
$color-payments-teal:          #00BC90;

$color-api-centre-grey:        #F5F5F5;
$color-api-centre-yellow:      #FFC428;
$color-api-centre-blue:        #009FDF;

// These colours are also used in the Stats JS React App in settings.js
$color-payments-light-blue:    rgba(0,159,223,1);
$color-payments-dark-blue:     rgba(32,45,81,1);
$color-payments-lighter-blue:  rgba(102,199,232,1);
$color-payments-dark-yellow:   rgba(243,189,12,1);
$color-payments-yellow:        rgba(255,207,50,1);
$color-payments-light-yellow:  rgba(255,223,118,1);
$color-payments-dark-orange:   rgba(239,84,20,1);
$color-payments-orange:        rgba(247,130,52,1);
$color-payments-light-orange:  rgba(255,167,108,1);
$color-payments-dark-teal:     rgba(26,156,174,1);
$color-payments-teal:          rgba(7,164,133,1);
$color-payments-light-teal:    rgba(104,212,227,1);
$color-payments-dark-red:      rgba(210,46,30,1);
$color-payments-red:           rgba(235,78,63,1);
$color-payments-light-red:     rgba(242,123,112,1);
$color-payments-dark-green:    rgba(11,137,112,1);
$color-payments-green:         rgba(90,180,91,1);
$color-payments-light-green:   rgba(73,194,172,1);
$color-payments-dark-purple:   rgba(91,29,117,1);
$color-payments-purple:        rgba(120,67,142,1);
$color-payments-light-purple:  rgba(177,115,203,1);


$color-payments-tolopea:       rgba(35, 25, 66, 1);
$color-payments-butterfly-bush:rgba(94, 84, 142, 1);
$color-payments-cold-purple:   rgba(159, 134, 192, 1);
$color-payments-east-side:     rgba(180, 132, 197, 1);
$color-payments-lilac:         rgba(190, 149, 196, 1);
$color-payments-shocking:      rgba(214, 154, 187, 1);
$color-payments-twilight:      rgba(224, 177, 203, 1);
$color-payments-tea-rose:      rgba(245, 147, 201, 1);

$color-payments-sherwood-green:rgba(28, 67, 50, 1);
$color-payments-viridian:      rgba(65, 145, 108, 1);
$color-payments-silver-tree:   rgba(115, 198, 157, 1);
$color-payments-granny-apple:  rgba(183, 228, 199, 1);


$color-payments-prussian-blue: rgba(1, 42, 74, 1);
$color-payments-dark-cerulean: rgba(2, 73, 124, 1);
$color-payments-lochmara:      rgba(41, 112, 151, 1);
$color-payments-boston-blue:   rgba(71, 143, 175, 1);
$color-payments-cornflower:    rgba(150, 207, 232, 1);
$color-payments-pattens-blue:  rgba(209, 240, 250, 1);

$color-payments-bright-turquoise:rgba(5, 215, 228, 1);
$color-payments-bunting:         rgba(31, 46, 81, 1);
$color-payments-royal-blue:      rgba(58, 115, 255, 1);
$color-payments-shamrock:        rgba(69, 220, 157, 1);
$color-payments-blue-violet:     rgba(126, 46, 228, 1);
$color-payments-brink-pink:      rgba(255, 109, 118, 1);

$color-api-centre-green:       rgba(120,198,117,0.3);

// Action colors
// $color-bg-color:            #F4F4F5;
// $color-go-green:            #56C97C;
// $color-yo-yellow:           #FFB945;
$color-white:               #fff;
$color-black:               #000;

$color-text-grey:           #555;

// Common Colors
$color-black-80:           #212020;
$color-black-70:           #333333;
$color-black-50:           #777777;
$color-black-20:           #D0D0D0;
$color-black-10:           #F4F5F5;


// $color-white-a70:           rgba(white, 0.7);
// $color-white-a50:           rgba(white, 0.5);
// $color-white-a30:           rgba(white, 0.3);














// // Text colors
$color-link-blue:           #0079CF;
$color-primary-blue:        #00AEEF;
$color-dark-blue:           #254184;




$color-text-blue-dark:      #005A74;
$color-text-red:            #FF0010;
$color-text-red-dark:       #5A0006;
$color-text-green:          #00B43B;
$color-text-green-dark:     #006C24;
$color-text-yellow:         #EC8500;
$color-text-yellow-dark:    #995600;

// Accessibilty Colors
$color-focus:               #6cc6ee;
$color-select:              #b3d4fc;
$color-error:               #f77;

// Sentiments
$color-error: #c0392b;
