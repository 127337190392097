.footer {
    background-color: $color-black;
    padding: 3.5rem 0 1.5rem;


    h5 a {
        color: $color-white;
    }

    a {
        color: rgba($color-white, 0.4);
        transition: $base-transition;

        &:hover {
            color: rgba($color-white, 0.9);
        }

    }

}

.footer_nav {
    font-size: 14px;
}

.footnote {
    border-top: 1px solid rgba(white, 0.1);
    margin-top: 50px;
    padding: 10px 0;

    a {
        padding: 0 5px;
        display: inline-block;
    }
}

.footer__nav-group__bottom-item {
    margin-top: 3rem;

    @include medium {
        margin-top: 2rem;
    }
}