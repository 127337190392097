.u-p--reset {
  padding: 0 !important;
}

.u-p--medium {
  padding: 1.5rem;
}

.u-p--large {
  padding: 2rem;
}

/************************
 * TOP
 ************************/
.u-pt--xlarge {
  padding-top: 3.2rem;
}

.u-pt--large {
  padding-top: 2rem;
}

.u-pt--small {
  padding-top: 1rem;
}

.u-pt--xxsmall {
  padding-top: .5rem;
}

.u-pt--xxxsmall {
  padding-top: .1rem;
}

.u-pt-0 {
  padding-top: 0 !important;
}

// TODO: this utility pretends to be generic, but it really is not and needs refactoring. -RichC
.sm-pt-xxsmall {
  @include xmedium {
    padding-top: 0;
  }
  @include small {
    padding-top: .5rem;
  }
  @include narrow {
    padding-top: .5rem;
  }
}


/************************
 * BOTTOM
 ************************/
// TODO: why is this !important.... seems like a horrible idea. should be a more generic class than that. -RichC
.u-pb--medium {
  padding-bottom: 3.2rem !important;
}

.u-pb--large {
  padding-bottom: 2rem;
}

.u-pb--xsmall {
  padding-bottom: 1rem;
}

.u-pb--xxsmall {
  padding-bottom: 0.5rem;
}

.u-pb-0 {
  padding-bottom: 0;
}

// TODO: this utility pretends to be generic, but it really is not and needs refactoring. -RichC
.md-pb-10 {
  @include narrow {
    padding-bottom: 10px;
  }
}

/************************
 * Y aka TOP & BOTTOM
 ************************/
 .u-py--medium {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

 .u-py--large {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.u-ptb--xxsmall {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.u-py--xlarge {
  padding-top: 4rem;
  padding-bottom: 4rem;
}

.lg-u-py--xlarge {
  @include large {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }
}

.narrow-u-py--large {
  @include narrow {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
}

.large-down-u-py--large {
  @include large-down {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
}

// TODO: this utility pretends to be generic, but it really is not and needs refactoring. -RichC
.sm-ptb--small {
  @include medium {
    padding-bottom: .4rem;
    padding-top: .4rem;
  }

  @include narrow {
    padding-bottom: 1rem;
    padding-top: 1rem;
  }
}

/************************
 * LEFT
 ************************/
.u-pl-0 {
  padding-left: 0;
}

.u-pl--xsmall {
  padding-left: 1rem;
}

.u-pl--medium {
  padding-left: 1.875rem;
}


/************************
 * RIGHT
 ************************/
.u-pr--xlarge {
  padding-right: 5rem;
}

.u-pr-xxsmall {
  padding-right: 0.5rem;;
}

.u-pr--small {
  padding-right: 1.5rem;
}

.u-pr--xsmall {
  padding-right: 1rem;
}

.u-pr--xxsmall {
  padding-right: 0.5rem;
}

.sm-pr--small {
  @include small {
    padding-right: 1rem;
  }
}

// TODO: this utility pretends to be generic, but it really is not and needs refactoring. -RichC
.u-pr-25 {
  padding-right: 0;

  @include large {
    padding-right: 25px;
  }
  @include medium {
    padding-right: 25px;
  }
}

/************************
 * X aka LEFT & RIGHT
 ************************/
.u-px--large {
  padding-left: 2rem;
  padding-right: 2rem;
}

.u-px-0 {
  padding-left: 0;
  padding-right: 0;
}

.u-px-10 {
  padding-left: 2.5rem;
  padding-right: 2.5rem;
}
