
.example_map {
    height:25rem;
    @include large {
        height: 30rem;
    }
}

.map-popup {
    padding: 25px 0 0 20px;
    text-align: center;
    h4 { font-size:1.5rem; }
    p { font-size:1rem; }
}
