
h1, h2, h3, h4
.h1, .h2, .h3, .h4 {
    // margin: 1.414em 0 0.5em;
    font-weight: inherit;
    line-height: 1.2;
    color: $color-black-70;

    &:first-child {
        margin-top: 0;
    }

}

h1,
.h1 {
    font-size: 52px;
    font-weight: 400;

    @include narrow {
        font-size: 36px;
    }

}

h2,
.h2 {
    font-size: 36px;
    font-weight: 400;

    @include narrow {
        font-size: 28px;
    }

}

h3,
.h3 {
    font-size: 20px;
    font-weight: bold;
    color: $color-black-70;

    @include narrow {
        font-size: 17px;
    }

}

h4,
.h4 {
    font-size: 16px;
    font-weight: bold;
    color: $color-black-70;

    @include narrow {
        font-size: 15px;
    }

}


h5, .h5,
h6, .h6 {
    font-weight: 600;
    text-transform: uppercase;

}


h5, .h5 {
    font-size: 14px;

    @include narrow {
        font-size: 13px;
    }

}


h6, .h6 {
    font-size: 12px;
    color: $color-black-50;

    @include narrow {
        font-size: 10px;
    }

}