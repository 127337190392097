
@import 'screen';

body {
    padding-top: 105px;
}

// -----------------------------------------------------------------------------
// TYPOGRAPHY
// -----------------------------------------------------------------------------

h1, h2, h3, h4, h5, .nav__link {
    font-family: 'Gilroy-SemiBold', Helvetica, Arial, sans-serif;
    font-weight: normal;
}

h1, .h1  {
    @include narrow {
        font-size: 28px;
    }
    @include medium {
        font-size: 40px;
    }
    @include large {
        font-size: 48px;
    }

}

h2, .h2 {
    @include narrow {
        font-size: 26px;
    }
    font-size: 36px;
    max-width: 100%;
}

h3, .h3 {
    @include narrow {
        font-size: 20px;
    }

    font-size: 24px;
}

h4, .h4 {
    font-size: 15px;
    letter-spacing: .67px;
    text-transform: uppercase;
}

h5, .h5 {
    font-size: 16px;
}

.main-content  {
    p + h2 {
        margin-top: 45px;
    }
}

// -----------------------------------------------------------------------------
// BUTTONS
// -----------------------------------------------------------------------------

.btn--primary--blue {
    background-color: #00AEEF;
}

.btn {
    background-color: #229fd7;
    border: none;

    &:hover {
        background-color: #00AEEF;
        text-decoration: none;
        color: white;
    }

}

.btn--secondary--white {
    color: white;

    &:hover {
        background-color: #00AEEF;
        text-decoration: none;
        color: white;
    }

    .icon-download {
        color: #fff;
        fill: #fff;
    }

}

// -----------------------------------------------------------------------------
// HEADER
// -----------------------------------------------------------------------------

.api-header {
    background-color: $color-api-centre-blue;

    @include medium {
        height: 105px;
    }

    a {
        color: white;

        &:hover {
            opacity: 0.8;
        }
    }
    #burger {
        @include medium {
            margin-left: unset;
        }
    }
}

.nav__link::before {
    bottom: -1px;
}

.homepage .api-header .nav__link::before {
    bottom: 17px !important;
}

.api-logo {
    margin-top: 15px;
    display: block;
}

.input-search {
    background-color: #00a3da;
    padding: 20px;
    color: #fff;
    border-bottom: 2px solid #fff;
}

.nav__link {
    font-size: 16px;
}

.mobile-nav__item:before {
    content: none;
}

// -----------------------------------------------------------------------------
// BANNERS
// -----------------------------------------------------------------------------

.banner-small {
    padding-bottom: 0;

    h1 + p {
        font-size: 24px;
        margin-top: 14px;
    }

    + .wrapper--ptb--medium {
        padding-top: 3em;
    }

}

.fancy-header:before {
    width: 0;
    height: 0;
}

.theme-lightgrey {
    background-color: #EBEBEB;
}

.icon-search {
    margin-bottom: 2px;
}

.banner--apicentre-homepage-wrapper {
    background-color: $color-api-centre-blue;
    padding: 0 1.5rem;

    @include medium {
        padding: 0 2.5rem;
    }
}

.banner--apicentre-homepage {
    display: flex;
    height: auto;

    max-width: 1360px;
    margin: 0 auto;

    padding-bottom: 1rem;

    @include medium {
        padding-bottom: 2rem;
    }

    @include xlarge {
        height: 540px;
    }
}

.banner--apicentre-homepage__left {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    position: relative;

    @include large {
        margin-right: 1rem;
    }

    .banner--apicentre-homepage__heading {
        display: flex;
        align-items: center;
        margin: 1rem 0;

        // makes sure "New Zealand" always appears on one line. If the copy changes then so must this
        max-width: 320px;
        @include medium {
            max-width: 500px;
        }
        @include xlarge {
            max-width: 550px;
        }
        @include large {
            flex-grow: 1;
        }

        h1 {
            font-size: 32px;
            font-weight: 600;
            line-height: 104%;
            color: white;

            @include medium {
                font-size: 50px;
                letter-spacing: 1px;
                line-height: 111%;
            }

            @include xlarge {
                font-size: 58px;
            }
        }
    }

    .banner--apicentre-homepage__logo {
        background-color: $color-api-centre-blue;
        position: absolute;
        margin: 6px 0;  // height of svg of 58px plus top and bottom margin of 6px each == height of ::before element
        margin-right: 2px
    }

    &::before {
        content: '';
        width: 100%;
        height: 70px;
        background-image: url('/static/images/api-center-bg-arrows.svg');
        background-repeat: repeat;
    }

    &::after {
        content: '';
        width: 100%;
        height: 114px;
        background-image: url('/static/images/api-center-bg-arrows.svg');
        background-repeat: repeat;
    }
}

.banner--apicentre-homepage__right {
    height: 100%;
    display: none;

    @include large {
        display: block;
    }

    img {
        height: 100%;
        width: auto;
        max-width: unset;
    }
}

.border-left {
    border-left: none!important;
}


// -----------------------------------------------------------------------------
// FORMS
// -----------------------------------------------------------------------------


.radio:checked+label:before,
.radio:hover+label:before,
.checkbox:checked+label:before,
.checkbox:hover+label:before {
    background: #00AEEF!important;
    border-color: #00AEEF!important;
}

.radio:checked+label:before {
    background: #00AEEF!important;
    border-color: #00AEEF!important;
    box-shadow: 0 0 0 4px #fff, 0 0 0 6px #00AEEF!important;
}

.input--required {
    color: #EB4E3F;
}

.input--required--radio {
    color: #EB4E3F;
}

.custom-checkbox>input:hover+.custom-checkbox-visible,
.custom-checkbox>input:hover:checked+.custom-checkbox-visible,
.custom-checkbox>input:checked+.custom-checkbox-visible {
    background: #00AEEF!important;
    border-color: #00AEEF!important;
}

.utility-menu .icon-settings {
    fill: #333333;
    color: #333333;
}



// -----------------------------------------------------------------------------
// SIDE-NAV
// -----------------------------------------------------------------------------

.side-nav-column>.h4 {
    color: #555555!important;
}

.side-nav__link.active {
    color: #0079CF!important;

    &:before {
        content: " ";
        position: absolute;
        left: 0;
        top: 0;
        width: 4px;
        background-color: #0079CF;
        bottom: 0;
    }

}

.side-nav__second-tier a.active {
    color: #0079CF!important;
}


// -----------------------------------------------------------------------------
// MAIN CONTENT
// -----------------------------------------------------------------------------

.main-content {
    background-color: #fff;
}

.full-bleed--off-white {
    background-color: #fff;
}

.full-bleed--contact {
    background: transparent;
}

.full-bleed--padded{
    padding-top: 35px;
    padding-bottom: 35px;

    @include medium {
        padding-top: 75px;
        padding-bottom: 75px;
    }

}

.nav-lower {
    height: 120px;
}


// -----------------------------------------------------------------------------
// SIDE-NAV
// -----------------------------------------------------------------------------

.scalable-img > img {
    max-width: 100%;
    height: auto;
}
.sponsor {
    background-color: white;
}

.flex-image > img {
    max-width: 100%;
    height: auto;

    @include medium {
        max-width: 100%;
        object-fit: contain;
    }
}


// -----------------------------------------------------------------------------
// TABLES
// -----------------------------------------------------------------------------

.table {
    border: none;
    overflow: auto;

    th {
        background: none;
        color: #00AEEF;
        border-right: none;
        border-bottom: 2px solid #00AEEF;
        font-size: 14px;
        padding: 17px 14px 17px 0;

        img {
            padding-left: 14px;
        }

    }

    td {
        border-right: none;
        min-width: 106px;
        font-size: 18px;
        padding: 17px 14px 17px 14px;
    }

    .sticky-col {
        background-color: $color-white;
        padding: 17px 14px 17px 0;
        white-space: nowrap;
        position: sticky;
        left: 0;
    }
}

.wrapper--table {
    position: relative;
    overflow-x: auto;

    margin-right: -1.5em;
    margin-top: 10px;
    margin-bottom: 40px;

    @include medium {
        margin-right: -2.5em;
    }

    @include large {
        margin-right: -4.5em;
    }
}

.locked {
    background-image: url('/static/images/padlock.svg');
    background-repeat: no-repeat;
    background-position-x: 100%;
    background-size: 20px;
    background-position-y: center;
    display: inline-block;
    padding-right: 30px;
}


.api-center .main-content .wrapper--small + .wrapper--small {
    margin-top: 40px;
}

.api-center .viewport .api-header {
    .wrapper #login-logout-search-header .global-search {
        &:after {
            content: none; // hide the divider on desktop
        }
    }
}

.api-center .api-header > .wrapper {
    padding: 0 1.5rem;
    @include large {
        padding: 0 2.5rem;
    }
}

.api_center--header_btn { // yellow buttons in header
    background-color: $color-api-centre-yellow;
    height: 38px;
    padding-top: 8px;
    padding-bottom: 0;
    margin-right: 0 !important;
    border-radius: 50rem;

    span {
        color: black;
        padding-left: 17px;
        padding-right: 17px;
        text-transform: capitalize;
    }
    &:hover {
        &:before {
            content: none;
        }
    }
    &:hover, &:focus {
        span {
            opacity: 0.8;
        }
    }
}

button.api_center--header_btn {
    border: none;
    padding-top: 4px;
}

// -----------------------------------------------------------------------------
// CARD
// -----------------------------------------------------------------------------

.card__flex {
    display: flex;
    height: 40px;
    margin-bottom: 15px;
}

.card__flex__image {
    align-self: flex-end
}

.footnote a {
    padding: 0;
}


// -----------------------------------------------------------------------------
// CALL TO ACTION BANNER
// -----------------------------------------------------------------------------

.cta-banner {
    padding: 30px !important;
    background-color: $color-payments-light-blue-cta;
}

.api-center--cta-banner {
    padding: 30px !important;
    background-color: $color-api-centre-yellow;

    div div img {
        max-width: 120px;
        display: block;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 1rem;

        @include large {
            margin-bottom: 0;
        }
    }
}

// -----------------------------------------------------------------------------
// Feature text and image block
// -----------------------------------------------------------------------------


.api-center--image-and-text {
    display: flex;
    flex-wrap: wrap;

    @include large {
        align-items: center;
    }

    .api-center--image-and-text-container-image-container,
    .api-center--image-and-text-container-text-container {
        width: 100%;
        margin-bottom: 1.5rem;
        @include bp768 {
            width: 50%;
            margin-bottom: 0;
        }
    }

    .api-center--image-and-text-container-image-container {
        display: flex;
        height: 100%;
        .api-center--image-and-text-image {
            width: 100%;
            height: auto;
        }
    }

    .api-center--image-and-text-container-text-container {
        display: flex;
        flex-direction: column;
        align-items: baseline;
        justify-content: center;

        .api-center--image-and-text-header {
            font-size: 1.25rem;
            @include bp768 {
                padding-top: 1rem;
            }
        }

        .api-center--image-and-text-link {
            margin-top: 1rem;
            display: inline-block;
            height: 38px;
            border-radius: 50rem;
            background-color: $color-api-centre-blue;
            text-align: center;
            color: white;
            font-weight: 600;
            padding: 5px 1.5rem 0;
        }
    }

    &.image-left {
        flex-direction: column-reverse;
        @include bp768 {
            flex-direction: row-reverse;
        }
        .api-center--image-and-text-container-text-container {
            @include bp768 {
                padding-left: 3rem;
            }
        }
    }
    &.image-right {
        flex-direction: column-reverse;
        @include bp768 {
            flex-direction: row;
        }
        .api-center--image-and-text-container-text-container {
            @include bp768 {
                padding-right: 3rem;
            }
        }
    }
}

// -----------------------------------------------------------------------------
// PRINT
// -----------------------------------------------------------------------------


@media print {

    *,
    *:before,
    *:after {
      color: #000 !important; // Black prints faster: h5bp.com/s
      text-shadow: none !important;
      background: transparent !important;
      box-shadow: none !important;
    }

    a,
    a:visited {
      text-decoration: underline;
    }

    a[href]:after {
      content: " (" attr(href) ")";
    }

    .viewport {
        height: 0;
    }

   }
