.u-mb-0 {
    margin-bottom: 0 !important;
}

.u-mt-0 {
    margin-top: 0 !important;
}

%no-top-margin-first {
    &:first-child {
        margin-top: 0;
    }
}

%no-bottom-margin-last {
    &:last-child {
        margin-bottom: 0;
    }
}

.u-m-reset {
  margin: 0;
}


// -----------------------------------------------------------------------------
// Margin Top
// -----------------------------------------------------------------------------

.u-mt--large {
  margin-top: 5rem;
}

.u-mt--medium {
  margin-top: 3.2rem;
}

.u-mt--small {
  margin-top: 2rem;
}

.u-mt--small--narrow {
  @include narrow {
    margin-top: 2rem;
  }
}

.u-mt--xsmall {
  margin-top: 1rem;
}

.u-mt--xxsmall {
  margin-top: 0.5rem;
}

.u-mt--xxxsmall {
  margin-top: 0.1rem;
}

.u-mtb--xxsmall {
  margin-bottom: 0.5rem;
  margin-top: 0.5rem;
}

%no-top-margin-first {
  &:first-child {
    margin-top: 0;
  }
}

// -----------------------------------------------------------------------------
// Margin Right
// -----------------------------------------------------------------------------

.md-mr--small {
  @include xmedium {
    margin-right: 1.5rem;
  }
}

.u-mr--medium {
  margin-right: 3em;
}

.u-mr--small {
  margin-right: 1.5rem;
}

.u-mr-5 {
  margin-right: 1.25rem;
}

.u-mr--0--small {
  @include narrow {
    margin-right: 0;
  }
}

.u-mr--xsmall {
  margin-right: 1rem;
}

.u-mr--xxsmall {
    margin-right: 0.5rem;
}

.u-mr--auto {
  margin-right: auto;
}


// -----------------------------------------------------------------------------
// Margin Bottom
// -----------------------------------------------------------------------------

.u-mb--large {
  margin-bottom: 5rem;
}

.u-mb--medium {
  margin-bottom: 3.2rem;
}

.u-mb--small {
  margin-bottom: 2rem;
}

.u-mb--xsmall {
  margin-bottom: 1rem;
}

.u-mb--xxsmall {
  margin-bottom: 0.5rem;
}

.u-mb-0 {
  margin-bottom: 0 !important;
}

%no-bottom-margin-last {
  &:last-child {
    margin-bottom: 0;
  }
}

// -----------------------------------------------------------------------------
// Margin Left
// -----------------------------------------------------------------------------

.xmd-ml--small {
  @include xmedium {
    margin-left: 1.5rem;
  }
}

.u-ml-10 {
  margin-left: 2.5rem;
}

.u-ml--xsmall {
  margin-left: 1rem;
}

.u-ml--xxsmall {
  margin-left: 0.5rem;
}

.u-ml--0 {
  margin-left: 0;
}

.u-ml--auto {
  margin-left: auto;
}

.u-m--auto {
  margin: auto;
}

// -----------------------------------------------------------------------------
// X and Y
// -----------------------------------------------------------------------------

.u-mx--xsmall {
  margin-left: 1rem;
  margin-right: 1rem;
}

.u-mx-auto {
  margin-left: auto;
  margin-right: auto;
}

// -----------------------------------------------------------------------------
// Helping Classes
// -----------------------------------------------------------------------------

.btn--spacing--25 {
  margin-top: 25px;
  margin-bottom: 25px;
}

.btn--spacing {
  margin-top: 3.2rem;
  margin-bottom: 3.2rem;
}

.lg-u-mb--medium {
  @include large {
    margin-bottom: 3.2rem;
  }
}

.lg-u-ml--xsmall {
  @include large {
    margin-left: 1rem;
  }
}

.lg-u-mb-0 {
  @include large {
    margin-bottom: 0;
  }
}

.lg-u-mt-0 {
  @include large {
    margin-top: 0;
  }
}
