div.responsive-table {
    overflow-x:auto;
}

table {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
    margin-bottom: 50px;

     &.table--align-right {
         th, td {
             text-align: right;
         }
     }

}

    th {
        text-align: left;
        border-bottom: 1px solid #ccc;
        padding: 18px 0 14px;
        margin-bottom: 5px;
    }

    td {
        padding: 6px 0;
    }

    th, td {
        &.cell--align-left {
            text-align: left !important;
        }
        &.cell--align-center {
            text-align: center !important;
        }
    }

table, tr, td, th, tbody, thead, tfoot {
    page-break-inside: avoid !important;

    div {
        page-break-inside: avoid !important;
    }
}


// -------------------------------------------------------------------
// Table Utilities?
// -------------------------------------------------------------------

.u-show-table-cell-xmedium {
    @include xmedium {
        display: table-cell;
    }
}
