.dropzone {
    width: 100%;
    height: 200px;
    border-style: dotted;
    border-color: $color-payments-light-grey;
    border-image-slice: 33% 33%;
    border-image-repeat: round;
    border-width: 8px;
    display: table;
    &.dropzone--active {
      border-color: #1F2E51;
    }

    div {
      display: table-cell;
      height: 200px;
      vertical-align: middle;
      text-align: center;
    }
}

.dropzone-file {
    position: relative;
}

.dropzone-delete {
    position: absolute;
    top: 3px;
    right: 0;
    z-index: 100;
}

.dropzone-file-container {
    max-width: 66%;
  @include narrow {
    max-width: 100%;
  }
}