.u-fullheight {
    height: 100vh;
}

.u-height-0 {
    height: 0%;
}

.u-height-100 {
    height: 100%;
}