
.u-hidden {
    display: none;
}

.u-hidden-small {
    @include narrow {
        display: none !important;
    }
}

.u-hidden-medium {
    @include medium {
        display: none;
    }
}

.u-hidden-xmedium {
    @include xmedium {
        display: none;
    }
}

.u-hidden-xmedium-down {
    @include xmedium-down {
        display: none;
    }
}


.u-hidden-large {
    @include large {
        display: none;
    }
}

// Hidding elements on Touch devices
.touch .u-hidden-touch {
    display: none;
    visibility: hidden;
}

// Hidding elements on Non Touch screens
.no-touch .u-hidden-no-touch {
    display: none;
    visibility: hidden;
}

// hides the edit icon in the registers tables on edit mode
.register-input-in-table:focus + .register-edit-icon {
    opacity: 0;
}

.register-search-bar-input {
    display: block;
    height: 70px;
    //width: 111px;
}
