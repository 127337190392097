/* this slider gets most of its styling from _components.image.slider.scss, with some small style changes in this file */
.slider--quotes {
  li {
    margin: 0;
    padding: 0;
  }

  p {
    font-size: 1.25em;
    font-weight: 700;
  }
}


