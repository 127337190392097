*:active,
*:focus {
    outline: none;
}

::selection {
    background: $color-select; // WebKit/Blink Browsers
}

::-moz-selection {
    background: $color-select; // Gecko Browsers
}
