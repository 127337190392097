// -----------------------------------------------------------------------------
// Media blocks
// -----------------------------------------------------------------------------
//
// Float an image to the left, with a clearfixed block of content to the right.
//
// <div class='media'>
//     <img src='assets/images/green-bird.jpg' class='media__image'>
//     <div class='media__body'>
//         <p>
//             The text
//         </p>
//     </div>
// </div>


$spacing-media: 2em;

.media,
.media--rev,
.media--middle-align {
    @include clearfix;
}

.media__image {
    @include narrow {
        margin-bottom: 1.5em;
    }
    @include medium {
        float: left;
        margin-right: $spacing-media;
    }
}

.media__body {
    @include clearfix;
    overflow:hidden;
    _overflow:visible;
    zoom:1;
    position: relative;

    .small {
        font-weight: 600;
    }

}


// Swap the image to the right
.media--rev > .media__image {
    float: right;
    margin-right: 0;
    margin-left: $spacing-media;
}

.media--rev > .media__body {
    @include clearfix;
}


// -----------------------------------------------------------------------------
// Modifiers
// -----------------------------------------------------------------------------

.media--middle-align {
    display: table;

    > .media__body {
        display: table-cell;
        vertical-align: middle;

        > * {
            margin: 0;
        }
    }
}


.media--border {
    border-top: 1px solid #E5EEF3;
    padding-top: 50px;

}


