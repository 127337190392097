.u-text-font-heading {
    font-family: 'Hepta-Slab-medium';
}

.u-text-light {
    font-weight: $font-weight-light;
}

.u-text-bold {
    font-weight: $font-weight-bold;
}

.u-text-uppercase {
    text-transform: uppercase !important;
}

.u-text-underline {
    text-decoration: underline !important;
}

.u-muted {
    color: #A2A3A3 !important;
}

.u-font-size\-\- {
    font-size: .9em;
}

.u-font-size\-\-\- {
    font-size: .75rem;
}

.u-font-size\+\+ {
    font-size: 1.2em;
}

.u-text-strike {
    text-decoration: line-through;
}

.u-text-transform-none {
    text-transform: none;
}

.u-text-top-line {
    position: relative;
    padding-top: 0.5rem;

    &:after {
        content: "";
        border-top: 3px solid white;
        position: absolute;
        width: 60px;
        top: 0;
        left: calc(50% - 30px);

        @include large {
            width: 90px;
            left: 0;
            top: 0;
        }
    }
}

.u-big {
    font-weight: $font-weight-medium;
    font-size: rem(18);

    @include medium {
        font-size: rem(21);
        line-height: 1.1;
    }

    @include large {
        font-size: rem(23);
        line-height: 1.05;
    }
}



// * 1. Ensures text doesn't wrap and break your layout
// * 2. Trigger overflow behavior
// * 3. Truncate the text
.u-text-truncate {
    overflow: hidden;               // [2]
    text-overflow: ellipsis;        // [3]
    white-space: nowrap;            // [1]
}


.u-hyphen {
    @include xsmall {
        // These are technically the same, but use both
        overflow-wrap: break-word;
        word-wrap: break-word;
        // This is the dangerous one in WebKit, as it breaks things wherever
        word-break: break-all;
        // Instead use this non-standard one:
        word-break: break-word;
        // Adds a hyphen where the word breaks, if supported (No Blink)
        hyphens: auto;
    }
}

.u-whitespace-pre {
    white-space: pre;
}

.u-whitespace-pre-wrap {
    white-space: pre-wrap;
}

.u-whitespace-no-wrap {
    white-space: nowrap;
}

.u-whitespace-normal {
    white-space: normal;
}
