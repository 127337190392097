.oldie-banner {
    position: fixed;
    top: 0;
    width: 100%;
    padding: 10px 0;
    margin: 0;
    text-align: center;
    border-bottom: 2px solid #f39c12;
    background-color: #f2d7ae;
    z-index: 1000;
    font-size: 16px;
    line-height: 18px;
    a {
        text-decoration: underline;
        color: #f39c12;
    }
}


.oldie-banner--bottom {
    bottom: 0;
    border-top:2px solid #f39c12;
}

/*
    These styles are to account for the fixed header. If you don't have one you don't need them.
    You could also just pin it to the bottom.
*/
.oldie {
    body { padding-top: 148px }
    .page__header { top:58px; }
}