// -----------------------------------------------------------------------------
// ALIGNMENTS
// -----------------------------------------------------------------------------


// vertical aligns

.u-align-middle {
    vertical-align: middle !important;
}

.u-align-bottom {
    vertical-align: bottom !important;
}


// text aligns

.u-text-left {
    text-align: left !important;
}

.u-text-right {
    text-align: right !important;
}

.u-text-center {
    text-align: center !important;
}


.lg-u-text-left {
    @include large {
        text-align: left !important;
    }
}

// margin center
// -----------------------------------------------------------------------------

.u-margin-center,
.u-auto {
    margin-left: auto !important;
    margin-right: auto !important;
}


// floats
// -----------------------------------------------------------------------------

.u-left {
    float: left !important;
}

.u-right {
    float: right !important;
}

.u-left-small {
    @include narrow {
        float: left !important;
    }
}


// clear
// -----------------------------------------------------------------------------

.u-block {
    display: block;
}

.u-clear {
    clear: both !important;
}

// Indents
.u-indent-left {
    padding-left: 1.5rem;
}

// Position Absolute
// -----------------------------------------------------------------------------

.u-top-xsmall {
    top: 1rem;
}

.u-right-xsmall {
    right: 1rem;
}

.u-right-small {
    right: 1.5rem;
}

.u-right-0 {
    right: 0;
}

