


// -----------------------------------------------------------------------------
// Wrappers for centering content
// -----------------------------------------------------------------------------
//
//
//
//
//
//


.wrapper--home {
    max-width: 1024px;
    padding-top: 2.5em;
    padding-bottom: 2.5em;
}



// Base
// ----------------------------------------------------------------------------
.wrapper {
    @include clearfix;
    margin-right: auto;
    margin-left:  auto;
}

// Modifiers
// ----------------------------------------------------------------------------

.wrapper--xlarge {
    max-width: 1400px;
}

.wrapper--large {
    max-width: 1280px;
}

.wrapper--medium {
    max-width: 950px;
}

.wrapper--small {
    max-width: 790px;
}

.wrapper--xsmall {
    max-width: 550px;
}

.wrapper--xxsmall {
    max-width: 450px;
}

.wrapper--plr--small {
    padding-left: 2em;
    padding-right: 2em;
}

.wrapper--ptb--small {
    padding-top: 2em;
    padding-bottom: 2em;
}

.wrapper--ptb--xmedium {
    padding-top: 3em;
    padding-bottom: 3em;
}

.wrapper--ptb--medium {
    padding-top: 4em;
    padding-bottom: 4em;
}

.wrapper--pt--small {
    padding-top: 2em;
}

.wrapper--pt--medium {
    padding-top: 4em;
}

.wrapper--pb--medium {
    padding-bottom: 4em;
}

.wrapper--ptb--large {
    padding-top: 6em;
    padding-bottom: 6em;
}


// -----------------------------------------------------------------------------
// Register Wrappers
// -----------------------------------------------------------------------------

.wrapper--registers {
    padding: 1em 0 1em 0;

    @include medium {
        padding: 1em 0 1em 1em;
    }

}

// -----------------------------------------------------------------------------
// Breakpoint Wrappers
// -----------------------------------------------------------------------------

.lg-wrapper--large {
    @include large {
        max-width: 1280px;
    }
}

.lg-wrapper {
    @include large {
        @include clearfix;
        margin-right: auto;
        margin-left:  auto;
        padding-left: 4.5em;
        padding-right: 4.5em;
    }
}

.narrow-wrapper {
    @include narrow {
        @include clearfix;
        margin-right: auto;
        margin-left:  auto;
        padding-left: 1.5em;
        padding-right: 1.5em;
    }
}
